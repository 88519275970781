import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
  colors: {
    brand: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#11047A',
      1050: '#0B1437',
    },
    _brand: {
      50: '#F3F0FF',
      100: '#E9E3FF',
      200: '#B9A2FF',
      300: '#9374FF',
      400: '#7551FF',
      500: '#4318FF',
      550: '#422AFB',
      600: '#3311DB',
      700: '#2200B7',
      800: '#190793',
      900: '#11047A',
      950: '#02044A',
      1000: '#111C44',
      1050: '#0B1437',
    },
    brandScheme: {
      100: '#E9E3FF',
      200: '#7551FF',
      300: '#7551FF',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    brandTabs: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#422AFB',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#1B2559',
    },
    red: {
      100: '#FEEFEE',
      400: '#EA4335',
      500: '#EE5D50',
      600: '#E31A1A',
    },
    blue: {
      50: '#EBF8FF',
      500: '#3965FF',
      600: '#2d50cc',
      800: '#2A4365',
      900: '#1A365D',
    },
    orange: {
      100: '#FFF6DA',
      200: '#FFF4D8',
      500: '#FFB547',
      600: '#FFEBB0',
    },
    green: {
      100: '#E6FAF5',
      200: '#9AE6B4',
      300: '#68D391',
      400: '#05CD99',
      500: '#01B574',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      700: '#1B254B',
      725: '#404a81',
      750: '#2B3674',
      800: '#111c44',
      900: '#0b1437',
    },
    gray: {
      100: '#FAFCFE',
      150: '#EDF2F7',
      200: '#E0E5F2',
      300: '#b3b7c1',
      // #CBD5E0 with 54 opacity
      360: 'rgba(203, 213, 224, 0.54)',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#1F2733',
    },
    _gray: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      350: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#1F2733',
      800: '#1A202C',
      900: '#171923',
    },
    purple: {
      600: '#805AD5',
    },
    pink: {
      500: '#D53F8C',
    },
    blackAlpha: {
      600: '#0000007A',
    },
    yellow: {
      100: '#FEFCBF',
      200: '#FAF089',
      300: '#f6e05e',
      500: '#ECC94B',
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('_gray.200', 'navy.900')(props),
        fontFamily: 'DM Sans',
        letterSpacing: '-0.5px',
      },
      input: {
        color: mode('gray.700', 'white')(props),
      },
      html: {
        fontFamily: 'DM Sans',
      },

      '@media (min-width: 768px)': {
        '*': {
          // Webkit (Chrome, Safari, Opera) styles
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
            height: '6px',
            background: '#E0E5F2',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'brand.500',
            borderRadius: '25px',
          },

          // Firefox styles
          '@supports (-moz-appearance:none)': {
            scrollbarWidth: 'thin',
            'scrollbar-color': '#422AFB #E0E5F2',
          },
        },
      },
    }),
  },
};
