import {
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Job } from '../../api';
import { SigningCreateDto, useCreateSigningMutation, useLazyGetSigningQuery } from '../../api/signing';
import { Sign } from '../../libs/ui/atoms/src';
import { FormInputField } from '../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { RadioButton } from '../../libs/ui/atoms/src/lib/radiobutton/RadioButton';
import { JobSelector } from '../JobSelector';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../libs/ui/atoms/src/lib/appButton';

enum SigningCreateStrategy {
  FROM_SINGLE_JOB = 'FROM_SINGLE_JOB',
  DIRECT = 'DIRECT',
}

export const SignButton = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [createSigning, { data, isLoading }] = useCreateSigningMutation();
  const [refetch, { data: signing, isLoading: isLoadingFetchSigning }] = useLazyGetSigningQuery();

  const [signingName, setSigningName] = useState<string | undefined>(undefined);
  const [tab, setTab] = useState<SigningCreateStrategy>(SigningCreateStrategy.DIRECT);
  const [job, setJob] = useState<Job | undefined>(undefined);

  const handleClick = useCallback(async () => {
    const dto: SigningCreateDto = {};
    if (tab === SigningCreateStrategy.FROM_SINGLE_JOB) {
      dto.jobId = job?.id;
    }
    if (tab === SigningCreateStrategy.DIRECT) {
      dto.name = signingName;
    }

    try {
      const result = await createSigning(dto).unwrap();
      await refetch({ signingId: result?.id });
      onClose();
      navigate(`/signings/${result?.id}`);
      setJob(undefined);
      setSigningName(undefined);
    } catch (error) {
      toast({
        title: t('error'),
        description: (error as { data?: Error })?.data?.message,
        status: 'error',
        duration: 1500,
        isClosable: true,
      });
    }
  }, [tab, job, signingName, createSigning, refetch, onClose, navigate, toast, t]);

  const clear = () => {
    setSigningName(undefined);
    setJob(undefined);
  };

  return (
    <>
      <AppButton
        buttonType={AppButtonType.SMALL}
        m={'2px 0 4px 1px'}
        cursor={'pointer'}
        padding="6px 8px"
        borderRadius="16px"
        fontWeight="semibold"
        bg="_brand.550"
        _hover={{ bg: '_brand.600' }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
        children={<Sign w="24px" h={'24px'} />}
      />

      <Modal size={'lg'} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="16" padding={'2'} paddingBottom="6">
          <ModalHeader>{t('pages.signing.createSigning')}</ModalHeader>
          <ModalCloseButton top={'6'} right="4" />

          <ModalBody display={'flex'} flexDirection="column" gap={'24px'}>
            <FormControl display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent="center">
              <RadioButton
                defaultValue={SigningCreateStrategy.DIRECT}
                value={tab}
                items={[
                  {
                    value: SigningCreateStrategy.DIRECT,
                    label: t('pages.signing.createStrategy.direct'),
                  },
                  {
                    value: SigningCreateStrategy.FROM_SINGLE_JOB,
                    label: t('pages.signing.createStrategy.fromSingleJob'),
                  },
                ]}
                onChange={e => {
                  setTab(e);
                  setJob(undefined);
                  setSigningName(undefined);
                }}
                justifyContent={'center'}
              />
            </FormControl>

            {tab === SigningCreateStrategy.FROM_SINGLE_JOB && (
              <JobSelector selectedJob={job} onJobSelect={job => setJob(job)} />
            )}

            {tab === SigningCreateStrategy.DIRECT && (
              <FormInputField label={t('description')} value={signingName} onChange={v => setSigningName(v)} />
            )}
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0" mt="24px">
            <AppButton
              buttonType={AppButtonType.MAIN}
              title={t('pages.details.cancel')}
              onClick={() => {
                onClose();
                clear();
              }}
              colorSchemes={[AppButtonColorScheme.DISABLED]}
            />

            <AppButton buttonType={AppButtonType.PRIMARY_MAIN} title={t('create')} onClick={handleClick} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
