import { FormControl, FormLabel, Grid, Show, Text, useBreakpointValue } from '@chakra-ui/react';
import { FormInputField } from '../../../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { useTranslation } from 'react-i18next';
import { SelectInputInputField } from '../../../../libs/ui/atoms/src/lib/input-field/selectField';
import { SignerFormAction, SignerFormState } from '../signer/useSignerForm';
import { COUNTRIES_WITH_CODES_PREPARED } from '../../../../libs/ui/atoms/src/lib/input-field/countries';
import { PhoneNumberInput } from '../../../../libs/ui/atoms/src/lib/input-field/phoneNumberInput';
import { VCDatePicker } from '../../../../libs/ui/atoms/src/lib/datePicker/DatePicker';
import { Dispatch } from 'react';
import { SignerSigningTypeSelect } from './signer-signing-type-select';
import { IdDocumentType } from '../../../../api/signer';

export interface SignerFormProps {
  isInConfirmView?: boolean;
  isDisabled?: boolean;
  form: SignerFormState;
  dispatch: Dispatch<SignerFormAction>;
  onFieldBlur: () => void;
}

const idTypeKeys: Record<IdDocumentType, string> = {
  [IdDocumentType.PASSPORT]: 'signer.form.idDocumentType.passport',
  [IdDocumentType.RP]: 'signer.form.idDocumentType.residencePermit',
  [IdDocumentType.CC]: 'signer.form.idDocumentType.idCard',
};

export const SignerForm = ({ isInConfirmView, isDisabled, form, dispatch, onFieldBlur }: SignerFormProps) => {
  const { t } = useTranslation();

  const templateColumns = useBreakpointValue({
    base: '1fr',
    md: isInConfirmView ? '1fr' : '1fr 1fr',
    xl: isInConfirmView ? '1fr' : '1fr 1fr 1fr',
    xxl: isInConfirmView ? '1fr 1fr' : '1fr 1fr 1fr',
  });

  return (
    <>
      {!isDisabled && (
        <Show breakpoint="(max-width: 1500px)">
          <SignerSigningTypeSelect
            mb="16px"
            isDisabled={isDisabled}
            signingType={form.signingType}
            onChange={value => dispatch({ type: 'setSigningType', value })}
          />
        </Show>
      )}

      <Grid templateColumns={templateColumns} gap={6}>
        <FormInputField
          isReadonly={isDisabled}
          isRequired={true}
          label={t('signer.form.name')}
          value={form.name}
          onChange={value => dispatch({ type: 'setName', value })}
          onBlur={onFieldBlur}
        />
        <FormInputField
          isReadonly={isDisabled}
          isRequired={true}
          label={t('signer.form.email')}
          value={form.email}
          onChange={value => dispatch({ type: 'setEmail', value })}
          onBlur={onFieldBlur}
        />
        <FormInputField
          isReadonly={isDisabled}
          label={t('signer.form.nif')}
          value={form.nif}
          onChange={value => dispatch({ type: 'setNif', value })}
          onBlur={onFieldBlur}
        />
        <FormControl>
          <FormLabel mb="4px">
            {t('signer.form.phone')}
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          </FormLabel>
          <PhoneNumberInput
            isReadonly={isDisabled}
            value={form.phone}
            onChange={value => dispatch({ type: 'setPhone', value })}
            onBlur={onFieldBlur}
          />
        </FormControl>

        <FormControl maxW={'100%'} overflow="hidden">
          <FormLabel
            mb="4px"
            maxW={'100%'}
            overflow="hidden"
            display={'flex'}
            title={t('signer.form.idDocumentType.label')}
          >
            <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
              {t('signer.form.idDocumentType.label')}
            </Text>
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          </FormLabel>
          <SelectInputInputField<IdDocumentType>
            isReadonly={isDisabled}
            placeholder={t('signer.form.idDocumentType.label')}
            items={Object.entries(idTypeKeys).map(([k, v]) => ({ value: k, label: t(v) }))}
            value={form.idDocumentType}
            onChange={value => !isDisabled && dispatch({ type: 'setIdDocumentType', value })}
            onBlur={onFieldBlur}
          />
        </FormControl>
        <FormControl maxW={'100%'} overflow="hidden">
          <FormLabel
            mb="4px"
            maxW={'100%'}
            overflow="hidden"
            display={'flex'}
            title={t('signer.form.idDocumentIssuerCountry')}
          >
            <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
              {t('signer.form.idDocumentIssuerCountry')}
            </Text>
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          </FormLabel>
          <SelectInputInputField
            isReadonly={isDisabled}
            placeholder={t('signer.form.idDocumentIssuerCountry')}
            items={COUNTRIES_WITH_CODES_PREPARED.map(i => ({
              value: i.alpha3,
              label: i.labelWithoutFlag + ' ' + i.flag,
            }))}
            value={form.idDocumentIssuerCountry}
            onChange={value => !isDisabled && dispatch({ type: 'setIdDocumentIssuerCountry', value })}
            onBlur={onFieldBlur}
          />
        </FormControl>

        <FormInputField
          isReadonly={isDisabled}
          isRequired={true}
          label={t('signer.form.idDocumentNumber')}
          value={form.idDocumentNumber}
          onChange={value => dispatch({ type: 'setIdDocumentNumber', value })}
          onBlur={onFieldBlur}
        />
        <FormControl maxW={'100%'} overflow="hidden">
          <FormLabel
            mb="4px"
            maxW={'100%'}
            overflow="hidden"
            display={'flex'}
            title={t('signer.form.idDocumentExpiryDate')}
          >
            <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
              {t('signer.form.idDocumentExpiryDate')}
            </Text>
            <Text as={'span'} color={'red'}>
              {' *'}
            </Text>
          </FormLabel>
          <VCDatePicker
            isDisabled={isDisabled}
            isNotInModal
            placeholder={t('signer.form.idDocumentExpiryDate')}
            selectedValue={form.idDocumentExpiryDate ? new Date(form.idDocumentExpiryDate) : undefined}
            onChange={value => value && dispatch({ type: 'setIdDocumentExpiryDate', value })}
            portalId={'signer-form-datepicker'}
            onBlur={onFieldBlur}
          />
        </FormControl>
      </Grid>
    </>
  );
};
