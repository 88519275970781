import { useCallback } from 'react';
import { Box, StyleProps, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { GoPlus } from 'react-icons/go';
import { t } from 'i18next';
import { SignerItem } from './signer-item';
import { SignerFormState } from '../signer/useSignerForm';
import { Signing } from '../../../../api/signing';
import { Signer, SignerUpdateDto } from '../../../../api/signer';
import { AppButton, AppButtonType } from '../../../../libs/ui/atoms/src/lib/appButton';

export interface SignersWidgetProps extends StyleProps {
  isReadonly?: boolean;
  isInConfirmView?: boolean;
  isNewSignerCreating?: boolean;
  signing: Signing;
  isLoading?: boolean;
  signers?: Signer[];
  onCreate?: (data: SignerFormState) => Promise<void>;
  onUpdate?: (dto: SignerUpdateDto) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onSignerCreateClick?: () => void;
  onSignerDeleted?: (id?: string) => void;
  onSignerCreated?: () => void;
  onSignerUpdated?: (id?: string) => void;
}

export const SignersWidget = ({
  isReadonly,
  isInConfirmView,
  isNewSignerCreating,
  signing,
  signers,
  isLoading,
  onCreate,
  onUpdate,
  onDelete,
  onSignerCreateClick,
  onSignerDeleted,
  onSignerCreated,
  onSignerUpdated,
  ...style
}: SignersWidgetProps) => {
  const toast = useToast();

  const handleCreate = useCallback(
    async (data: SignerFormState) => {
      try {
        await onCreate?.(data);
        onSignerCreated?.();
        toast({ title: t('createdSuccessfully'), status: 'success', duration: 1500 });
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as { data: Error }).data?.message,
          status: 'error',
          duration: 5000,
        });
      }
    },
    [onCreate, onSignerCreated, toast],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await onDelete?.(id);
        onSignerDeleted?.(id);
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as { data: Error }).data?.message,
          status: 'error',
          duration: 5000,
        });
      }
    },
    [onDelete, onSignerDeleted, toast],
  );

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  return (
    <Card flexDirection="column" w="100%" p="24px" overflowX={{ sm: 'hidden', lg: 'hidden' }} {...style}>
      {signers?.map((signer, idx) => (
        <SignerItem
          isInConfirmView={isInConfirmView}
          key={signer.id}
          signer={signer}
          isDisabled={!!signing?.submission || isReadonly}
          onUpdate={onUpdate}
          onDelete={() => handleDelete(signer.id)}
          isShowBorderBottom={idx !== signers.length - 1 || isNewSignerCreating}
          signingStatus={signing.submission?.status}
        />
      ))}

      {isNewSignerCreating && <SignerItem onCreate={handleCreate} onDelete={() => onSignerDeleted?.()} />}

      {!isReadonly && !signing?.submission && (
        <AppButton
          buttonType={AppButtonType.PRIMARY}
          leftIcon={<GoPlus size={'20'} />}
          isDisabled={isNewSignerCreating}
          w="fit-content"
          mt={!signers?.length && !isNewSignerCreating ? 0 : '16px'}
          onClick={() => !isNewSignerCreating && onSignerCreateClick?.()}
          title={t('signer.addNewSigner')}
        />
      )}
    </Card>
  );
};
