import { Button, CloseButton, Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandDiagonal } from 'libs/ui/atoms/src';
import { useAppDispatch, useTypedSelector } from '../../store';
import { tickets } from '../../views/admin/home/tickets';
import { helpPanelClosed, helpPanelCollapsed, helpPanelExpanded } from '../../store/help-tools';
import { OverlayMaybe } from '../../libs/ui/atoms/src/lib/overlatMaybe/OverlayMaybe';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

const updateHelpPanelHeight = (e: Event) => {
  const scrollTop = (e?.target as HTMLElement)?.scrollTop;
  const helpPanel = document.querySelector('.help-panel') as HTMLElement;
  const navbarHeight = (document.querySelector('.admin-navbar') as HTMLElement)?.offsetHeight;
  // 132px is header height, 30px is gap between header and help panel
  if (helpPanel) helpPanel.style.minHeight = `calc(100svh - 132px + min(${scrollTop}px, ${navbarHeight - 30}px))`;
};

export const HelpPanel = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const ticketType = useTypedSelector(state => state.helpTools.selectedHelpTicketType);
  const isCollapsed = useTypedSelector(state => state.helpTools.isHelpTicketPanelCollapsed);

  const isOverlayOpen = useBreakpointValue({ base: true, 1280: true, xxl: false });

  const ticket = tickets.find(t => t.type === ticketType);
  const isOpen = !!ticketType && !!ticket;

  const textColor = useColorModeValue('brand.800', 'white');
  const bg = useColorModeValue('blue.50', 'navy.750');
  const shadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.32)',
    '14px 17px 40px 8px rgba(112, 144, 176, 0.06)',
  );
  const widgetBg = useColorModeValue('white', 'navy.800');
  const stepBgSecondary = useColorModeValue('gray.50', 'navy.700');
  const stepBg = useColorModeValue('white', 'navy.800');
  const stepsNumberColor = useColorModeValue('_gray.500', 'gray.200');
  const stepsColor = useColorModeValue('_gray.600', 'gray.200');

  useEffect(() => {
    const scrollableContainer = document.querySelector('.root-scrollable-container');
    scrollableContainer?.addEventListener('scroll', updateHelpPanelHeight);
    return () => scrollableContainer?.removeEventListener('scroll', updateHelpPanelHeight);
  }, []);

  return isOpen ? (
    isCollapsed ? (
      <Button
        bg={bg}
        boxShadow={shadow}
        position={'absolute'}
        right="30px"
        bottom={'30px'}
        onClick={() => dispatch(helpPanelExpanded())}
        display="flex"
        gap="16px"
        height={'unset'}
        p="16px"
      >
        {ticket?.icon}
        <ExpandDiagonal w="20px" h={'20px'} />
      </Button>
    ) : (
      <OverlayMaybe
        isUseOverlay={!!isOverlayOpen}
        isVisible={isOpen && !isCollapsed}
        onClose={() => dispatch(helpPanelCollapsed())}
      >
        <Flex
          className="help-panel"
          bg={widgetBg}
          position="sticky"
          top="30px"
          right={isOverlayOpen ? '30px' : '0'}
          maxW="min(400px, 100svw)"
          maxH="calc(100vh - 132px)"
          gap="8px"
          flexDir={'column'}
          p="16px"
          me={'30px'}
          mb="30px"
          borderRadius="16px"
        >
          <Flex alignItems={'center'} gap="16px">
            <Flex alignItems={'center'} gap="16px" color={textColor}>
              {ticket?.icon}
              <Text fontWeight={'bold'} fontSize={'16px'} lineHeight={'21px'}>
                {t(ticket?.explanationTitle ?? '')}
              </Text>
            </Flex>

            <CloseButton
              color={textColor}
              className={'chakra-modal__close-btn'}
              onClick={() => dispatch(helpPanelCollapsed())}
            />
          </Flex>

          <Flex flexDirection={'column'} gap={'0'} alignItems={'center'} overflow="auto" flex="1">
            {ticket?.explanationSteps.map((step, idx) => (
              <Flex
                key={idx}
                width={'100%'}
                p="12px"
                alignItems={'center'}
                gap="16px"
                bg={idx % 2 === 0 ? stepBg : stepBgSecondary}
              >
                <Text fontSize={'14px'} lineHeight={'18px'} color={stepsNumberColor}>
                  {idx + 1}
                </Text>
                <Text key={idx} fontSize={'14px'} lineHeight={'18px'} width={'100%'} color={stepsColor}>
                  {t(step)}
                </Text>
              </Flex>
            ))}
          </Flex>

          <AppButton
            buttonType={AppButtonType.MAIN}
            title={t('help.imDoneCloseThisHelpPanel')}
            onClick={() => dispatch(helpPanelClosed())}
          />
        </Flex>
      </OverlayMaybe>
    )
  ) : null;
};
