export enum AppButtonType {
  /**
   * MAIN Button from design system
   */
  MAIN = 'MAIN',

  /**
   * Button is similar to MAIN, only with larger dimensions
   */
  PRIMARY_MAIN = 'PRIMARY_MAIN',

  /**
   * PRIMARY Button from design system
   */
  PRIMARY = 'PRIMARY',

  /**
   * SECONDARY Button from design system
   */
  SECONDARY = 'SECONDARY',

  /**
   * SMALL Button from design system
   */
  SMALL = 'SMALL',

  /**
   * XSMALL Button from design system
   */
  XSMALL = 'XSMALL',

  /**
   * Button used for icons
   */
  ICON = 'ICON',
}
