import { Box, Flex } from '@chakra-ui/react';
import { SortingState } from '@tanstack/react-table';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import { Signing, SigningListResponse, useLazyGetSigningsQuery } from '../../../api/signing';
import { useTypedSelector } from '../../../store';
import { sortChanged, tabChanged } from '../../../store/signing';
import { SigningTabKind, signingTabKinds, SigningTable } from './components/signing-table';

const take = 20;

interface SigningsListFilter {
  sorting: SortingState;
  skip: number;
  status?: SubmissionSigningStatus | null;
  loadTrigger?: unknown[];
}

const filterActionByTab: Record<SigningTabKind, () => SigningFilterActions> = {
  [SigningTabKind.ALL]: () => {
    return { status: null };
  },
  [SigningTabKind.SENT]: (): SigningFilterActions => {
    return { status: SubmissionSigningStatus.SENT };
  },
  [SigningTabKind.SIGNED]: (): SigningFilterActions => {
    return { status: SubmissionSigningStatus.SUCCESS };
  },
  [SigningTabKind.DECLINED]: (): SigningFilterActions => {
    return { status: SubmissionSigningStatus.REJECT };
  },
};

type SigningFilterActions =
  | { sorting: SortingState }
  | { skip: number }
  | { status: SubmissionSigningStatus | null }
  | { isNextPage: true }
  | {
      loadTrigger: unknown[];
      sorting?: SortingState;
      skip?: number;
      status?: SubmissionSigningStatus | null;
      isNextPage?: true;
    };

export const SigningListPage = () => {
  const { t } = useTranslation();

  const [fetchSigning, { isLoading, isFetching }] = useLazyGetSigningsQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const signings: SigningListResponse[] = useTypedSelector(state => state.signing.signings ?? []);
  const signingsTotalCount = useTypedSelector(state => state.signing.signingTotalCount ?? []);

  const [signingsFilter, dispatchSigningFilter] = useReducer(
    (state: SigningsListFilter, action: SigningFilterActions) => {
      if ('loadTrigger' in action) {
        return {
          ...state,
          loadTrigger: [],
          sorting: action.sorting === undefined ? state.sorting : action.sorting,
          skip: action.isNextPage ? signings.length : 0,
          status: action.status === undefined ? state.status : action.status,
        };
      }

      if ('isNextPage' in action) {
        return { ...state, skip: signings.length };
      }
      if ('sorting' in action) {
        return { ...state, skip: 0, sorting: action.sorting };
      }
      if ('status' in action) {
        return { ...state, skip: 0, status: action.status };
      }
      return state;
    },
    { sorting: [], skip: 0, status: undefined, loadTrigger: [] },
  );
  const [isNeedToFetchNextPage, setIsNeedToFetchNextPage] = useState(false);
  const signingSorting = signingsFilter.sorting;
  const skip = signingsFilter.skip;
  const signingStatus = signingsFilter.status;
  const loadTrigger = signingsFilter.loadTrigger;
  const signingColumnSort = signingSorting[0];

  const orderBy = signingColumnSort?.id;
  const orderDirection = signingColumnSort ? (signingColumnSort?.desc ? 'DESC' : 'ASC') : undefined;

  useEffect(() => {
    dispatchSigningFilter(filterActionByTab[SigningTabKind.ALL]());
  }, []);

  const load = useCallback(() => {
    fetchSigning({
      skip: skip,
      take: take,
      orderBy: orderBy,
      orderDirection: orderDirection,
      status: signingStatus ?? undefined,
    });
  }, [fetchSigning, skip, orderBy, orderDirection, signingStatus]);

  useEffect(() => {
    if (signingStatus === undefined) {
      return;
    }

    load();
  }, [load, signingStatus, loadTrigger]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentTab = (searchParams.get('tab') as SigningTabKind) ?? SigningTabKind.ALL;

    const currentTabIndex = signingTabKinds.findIndex(i => i === currentTab);
    setTabIndex(typeof currentTabIndex === 'number' && currentTabIndex !== -1 ? currentTabIndex : 0);
    dispatch(tabChanged());
    if (currentTab) {
      dispatchSigningFilter({ ...filterActionByTab[currentTab](), loadTrigger: [] });
    }
  }, [dispatch, location]);

  const onChangeTab = useCallback(
    (index: number) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tab', signingTabKinds[index]);
      navigate({ pathname: window.location.pathname, search: searchParams.toString() });
    },
    [location, navigate],
  );

  const fetchNextPage = useCallback(() => {
    if (isFetching || isLoading) {
      setIsNeedToFetchNextPage(true);
      return;
    }
    dispatchSigningFilter({ isNextPage: true });
  }, [isLoading, isFetching]);

  const onClickSigning = useCallback(
    (signing: Signing) => {
      if (!signing || !signing.id) {
        console.error('Invalid application state! Not found signingId.');
        return;
      }
      navigate(`/signings/${signing.id}`);
    },
    [navigate],
  );

  if (isNeedToFetchNextPage) {
    dispatchSigningFilter({ isNextPage: true });
    setIsNeedToFetchNextPage(false);
  }

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  return (
    <Flex direction="column" gap="24px">
      <SigningTable
        tabIndex={tabIndex}
        signings={signings}
        sorting={signingSorting}
        onChangeTab={onChangeTab}
        onSortingChange={updaterOrValue => {
          if (typeof updaterOrValue === 'function') {
            updaterOrValue = updaterOrValue(signingSorting);
          }
          dispatchSigningFilter({ sorting: updaterOrValue });
          setTimeout(() => dispatch(sortChanged()));
        }}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        totalCount={signingsTotalCount}
        onClickSigning={onClickSigning}
      ></SigningTable>
    </Flex>
  );
};
