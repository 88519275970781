import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { useTypedSelector } from '../../../../../store';
import { useUpdateJobMutation } from '../../../../../api';
import { selectJob } from '../../../../../store/job';
import { useGetTemplatesQuery } from 'api/template';
import { MaybeWrapAccordion } from './MaybeWrapAccordion';
import { Cancel, Magnifier } from '../../../../../libs/ui/atoms/src';
import { AppButton, AppButtonType } from '../../../../../libs/ui/atoms/src/lib/appButton';

interface JobTemplate {
  id: string;
  isSelected: boolean;
  name: string;
  description: string;
}

const columnHelper = createColumnHelper<JobTemplate>();

const ColumnsWidth = ['2%', '48%', '50%'];
const ColumnsMinWidth = ['20px', '150px', '150px'];

interface JobTemplatesWidgetProps {
  jobId: string;
  isHideIncludeContracts?: boolean;
  title?: string;
  isAccordion?: boolean;
}

export function JobTemplatesWidget({ jobId, title, isHideIncludeContracts, isAccordion }: JobTemplatesWidgetProps) {
  const { t, i18n } = useTranslation();

  const [isTemplatesEnabled, setIsTemplatesEnabled] = useState(true);

  const textColor = useColorModeValue('brand.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const [updateJob] = useUpdateJobMutation();
  const { data, isLoading, isFetching } = useGetTemplatesQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));

  const templates = useMemo<JobTemplate[]>(() => {
    return (
      data?.map(i => ({
        id: i.id,
        isSelected: !!job?.templates?.find(t => t.id == i.id),
        name: i18n.language == 'pt' ? i.namePt : i.nameEn,
        description: i18n.language == 'pt' ? i.descriptionPt : i.descriptionEn,
      })) ?? []
    );
  }, [i18n.resolvedLanguage, job, data]);

  const [search, setSearch] = useState('');
  const [templatesShown, setTemplatesShown] = useState([...templates]);

  useEffect(() => {
    setTemplatesShown([...templates.filter(i => i.name.toLowerCase().includes(search.toLowerCase()))]);
  }, [search, templates]);

  const [optimisticTemplates, setOptimisticTemplates] = useState<JobTemplate[]>([]);

  useEffect(() => {
    setOptimisticTemplates(templates);
  }, [templates]);

  const getToggledTemplateIds = useCallback(
    (templateId: string) => {
      const isTemplateSelected = !!templates.find(i => i.id === templateId && i.isSelected);
      const currentSelectedTemplateIds = templates.filter(i => i.isSelected).map(i => i.id);
      return isTemplateSelected
        ? currentSelectedTemplateIds.filter(i => i !== templateId)
        : [...currentSelectedTemplateIds, templateId];
    },
    [job, templates],
  );

  const handleCheckboxClick = async (templateId: string) => {
    if (!job?.id) return;

    const updatedTemplates = optimisticTemplates.map(template =>
      template.id === templateId ? { ...template, isSelected: !template.isSelected } : template,
    );
    setOptimisticTemplates(updatedTemplates);

    try {
      await updateJob({
        id: job.id,
        templateIds: updatedTemplates.filter(template => template.isSelected).map(template => template.id),
      }).unwrap();
    } catch (error) {
      setOptimisticTemplates(templates);
    }
  };

  const columns = [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: () => null,
      cell: info => (
        <Flex align="center">
          <AppButton
            buttonType={AppButtonType.ICON}
            onClick={() => {
              return handleCheckboxClick(info.row.original.id);
            }}
            children={
              <Icon
                as={info.getValue() ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
                color={info.getValue() ? 'green.300' : 'secondaryGray.600'}
                w="24px"
                h="24px"
              />
            }
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }}>
            {t('pages.details.name')}
          </Text>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Text color={textColor} p="16px" fontSize="14px">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            Description
          </Text>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: search ? templatesShown : optimisticTemplates,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onTemplatesToggle = useCallback(() => {
    setIsTemplatesEnabled(!isTemplatesEnabled);
    if (isTemplatesEnabled) {
      updateJob({ id: jobId, templateIds: [] });
    }
  }, [jobId, isTemplatesEnabled]);

  return (
    <Card flexDirection="column" w="100%" p={isAccordion ? '0' : '24px'} position="static">
      <Flex direction="column" gap="16px">
        <MaybeWrapAccordion
          isAccordion={isAccordion}
          buttonChildren={
            <Flex justifyContent={'space-between'} alignItems={'center'} p={isAccordion ? '24px' : 0} width={'100%'}>
              <Text fontSize={'20px'} color={textColor} fontWeight="700">
                {title ?? t('pages.details.step1Title')}
              </Text>

              {!isHideIncludeContracts && (
                <Flex alignItems={'center'} gap="8px">
                  <Text fontSize={'14px'} fontWeight={'bold'}>
                    {t('pages.details.includeContracts')}
                  </Text>
                  <Switch colorScheme="green" isChecked={isTemplatesEnabled} onChange={onTemplatesToggle} />
                </Flex>
              )}
            </Flex>
          }
        >
          {isTemplatesEnabled && (
            <Box
              position="relative"
              w="100%"
              overflowX="auto"
              maxH={'300px'}
              overflow="auto"
              p={isAccordion ? '0 16px 24px 16px' : 0}
            >
              <Flex p={'1px 0 24px 0'}>
                <InputGroup borderRadius={'10px'}>
                  <InputLeftElement pointerEvents="none">
                    <Magnifier color={textColor} />
                  </InputLeftElement>
                  <Input
                    alignSelf={'flex-end'}
                    w={'100%'}
                    borderRadius={'10px'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    placeholder={t('search')}
                    color={textColor}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                  {search && (
                    <InputRightElement cursor={'pointer'} onClick={() => setSearch('')}>
                      <Cancel color={textColor} w="12px" h="12px" />
                    </InputRightElement>
                  )}
                </InputGroup>
              </Flex>
              <Table variant="simple" color="gray.500">
                <Thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, idx) => {
                        return (
                          <Th
                            key={header.id}
                            colSpan={header.colSpan}
                            p="0 8px 4px 8px"
                            w={ColumnsWidth[idx]}
                            minW={ColumnsMinWidth[idx]}
                            borderColor={borderColor}
                            cursor="pointer"
                            textTransform="capitalize"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <Flex
                              justifyContent="space-between"
                              align="center"
                              fontSize={{ sm: '10px', lg: '12px' }}
                              color="gray.400"
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </Flex>
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>

                <Tbody>
                  {table.getRowModel().rows.map(row => {
                    return (
                      <Tr key={row.id} h="48px">
                        {row.getVisibleCells().map((cell, idx) => {
                          return (
                            <Td
                              key={cell.id}
                              fontSize={{ sm: '14px' }}
                              borderColor="transparent"
                              p="0"
                              paddingRight="16px"
                              w={ColumnsWidth[idx]}
                              minW={ColumnsMinWidth[idx]}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          )}
        </MaybeWrapAccordion>
      </Flex>
    </Card>
  );
}
