import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Exclamation } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

interface DeleteUserConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteUserConfirmModal = ({ isOpen, onClose, onConfirm }: DeleteUserConfirmModalProps) => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('brand.800', 'white');

  return (
    <Modal size={'md'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent color={textColor} borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalBody>
          <Flex flexDirection={'column'} gap={'16px'} alignItems={'center'}>
            <Exclamation w="64px" h="64px" color={'red'} />

            <Text fontSize="20px" fontWeight={'bold'} color={textColor} textAlign="center" pb={'16px'}>
              {t('attentionYouAreDeletingUser')}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          <AppButton
            buttonType={AppButtonType.MAIN}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
            onClick={onClose}
            title={t('cancel')}
          />
          <AppButton buttonType={AppButtonType.MAIN} onClick={onConfirm} title={t('ok')} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
