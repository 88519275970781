import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SubmissionSigningStatus, UniversalDocumentStatus } from '../../../../api';
import { SigningSingleResponse, useNotifySignersMutation } from '../../../../api/signing';
import Card from '../../../../components/card/Card';
import { WaitPleasePopup } from '../../../../components/waitPleasePopup/WaitPleasePopup';
import { SignersWidget } from '../components/signers-widget';
import { SigningMainInfoWidget } from '../components/signing-main-info';
import { SigningDetailsDocumentsWidget } from './SigningDocumentsWidget';
import { useSigners } from '../signer/use-signers';
import { SignerStatus } from '../../../../api/signer';
import { useAppToast } from '../../../../libs/ui/hooks';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../libs/ui/atoms/src/lib/appButton';

export interface SigningDetailsWidgetProps {
  isLoadingSubmit: boolean;
  name?: string;
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
  onSubmit: () => void;
  onSingersCountChanged: () => void;
  onNameChanged: (value: string) => void;
}

export const SigningDetailsWidget = ({
  signing,
  name,
  onDocumentsChanged,
  onSubmit,
  onSingersCountChanged,
  isLoadingSubmit,
  onNameChanged,
}: SigningDetailsWidgetProps) => {
  const { t } = useTranslation();
  const { showSuccess, showError, showInfo, showWarning } = useAppToast();
  const navigate = useNavigate();

  const [isNewSignerCreating, setIsNewSignerCreating] = useState(false);
  const [notifySigners, { isLoading: isNotifying }] = useNotifySignersMutation();

  const { isLoading: isSignersLoading, signers, onCreate, onUpdate, onDelete } = useSigners({ signingId: signing.id });

  const isSignersValid = useMemo(() => {
    return signers?.every(signer => signer.isValid);
  }, [signers]);

  const isSubmitDisabled = useMemo(() => {
    return (
      !signers?.length ||
      !signing?.documents?.length ||
      !isSignersValid ||
      signing?.documents.some(d => d.status === UniversalDocumentStatus.CREATED)
    );
  }, [signers, signing.documents, isSignersValid]);

  const handleSubmit = useCallback(() => {
    if (isNewSignerCreating) {
      showWarning(t('pages.signing.unsavedChanges'));
      return;
    }

    onSubmit();
  }, [isNewSignerCreating, onSubmit, t, showWarning]);

  const handleNotifyAllSigners = useCallback(async () => {
    if (!signers || !signers.length) {
      throw new Error('No signers available');
    }

    const unsignedSignerIds = signers.filter(signer => signer.status === SignerStatus.INIT).map(signer => signer.id);

    if (!unsignedSignerIds.length) {
      showInfo(t('pages.signing.allSigned'));
      return;
    }

    try {
      await notifySigners({ signerIds: unsignedSignerIds }).unwrap();
      showSuccess(t('pages.signing.signersNotifiedSuccessfully'));
    } catch (error) {
      showError((error as { data: Error }).data?.message);
    }
  }, [notifySigners, signers, t, showSuccess, showError, showInfo]);

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  return (
    <Flex direction="column" gap="24px" p="24px">
      <Card
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
        display={'flex'}
        flexDirection="column"
        gap={'24px'}
      >
        <SigningMainInfoWidget name={name} signing={signing} onNameUpdate={onNameChanged} />

        <Flex direction="column" borderRadius={'20px'} overflow="hidden" bg={widgetBackgroundColor}>
          <SigningDetailsDocumentsWidget signing={signing} onDocumentsChanged={onDocumentsChanged} />
        </Flex>

        <SignersWidget
          signing={signing}
          signers={signers}
          isLoading={isSignersLoading}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isNewSignerCreating={isNewSignerCreating}
          onSignerCreateClick={() => setIsNewSignerCreating(true)}
          onSignerDeleted={() => {
            setIsNewSignerCreating(false);
            onSingersCountChanged();
          }}
          onSignerCreated={() => {
            setIsNewSignerCreating(false);
            onSingersCountChanged();
          }}
        />

        {!signing?.submission && (
          <Flex gap="24px" justifyContent="end" direction={{ base: 'column', md: 'row' }} position="relative">
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              onClick={() => navigate(-1)}
              title={t('pages.details.cancel')}
              colorSchemes={[AppButtonColorScheme.DISABLED]}
            />
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              onClick={handleSubmit}
              isDisabled={isSubmitDisabled}
              isLoading={isLoadingSubmit}
              title={t('pages.details.submit')}
              tooltip={isSubmitDisabled ? t('pages.signing.submitDisabledTitle') : ''}
            />
          </Flex>
        )}

        {signing?.submission?.status === SubmissionSigningStatus.SENT &&
          signers?.length &&
          signers.some(signer => signer.status === SignerStatus.INIT) && (
            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={handleNotifyAllSigners}
              isLoading={isNotifying}
              ml="auto"
              title={t('pages.signing.notifyAllSigners')}
              colorSchemes={[AppButtonColorScheme.WARNING]}
            />
          )}
      </Card>

      <WaitPleasePopup signing={signing} />
    </Flex>
  );
};
