import { useCallback, useReducer } from 'react';
import { isEmailValid, isNifValid } from 'utils/validators';
import { UserRole } from '../../../../../../api';

type SetInitialStateAction = { type: 'setInitialState'; value: UserFormState };
type SetNameAction = { type: 'setName'; value: string };
type SetEmailAction = { type: 'setEmail'; value: string };
type SetNifNipsAction = { type: 'setNifNips'; value: string };
type SetAddressAction = { type: 'setAddress'; value: string };
type SetLocationAction = { type: 'setLocation'; value: string };
type SetPostalCodeAction = { type: 'setPostalCode'; value: string };
type ToggleRoleAction = { type: 'toggleRole'; value: UserRole };

export type UserFormAction =
  | SetInitialStateAction
  | SetNameAction
  | SetEmailAction
  | SetNifNipsAction
  | SetAddressAction
  | SetLocationAction
  | SetPostalCodeAction
  | ToggleRoleAction;

export interface UserFormState {
  name?: string;
  email?: string;
  nif?: string;
  address?: string;
  postalCode?: string;
  location?: string;

  roles: UserRole[];
}

export const initUserFormData: UserFormState = {
  name: undefined,
  email: undefined,
  nif: undefined,
  address: undefined,
  postalCode: undefined,
  location: undefined,

  roles: [],
};

export function useUserForm() {
  const [form, dispatch] = useReducer((state: UserFormState, action: UserFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return { ...state, ...action.value };
      case 'setName': {
        return { ...state, name: action.value };
      }
      case 'setEmail': {
        return { ...state, email: action.value ? action.value.trim() : '' };
      }
      case 'setNifNips': {
        return { ...state, nif: action.value };
      }
      case 'setAddress': {
        return { ...state, address: action.value };
      }
      case 'setLocation': {
        return { ...state, location: action.value };
      }
      case 'setPostalCode': {
        return { ...state, postalCode: action.value };
      }
      case 'toggleRole': {
        const roles = state.roles.includes(action.value)
          ? state.roles.filter(r => r !== action.value)
          : [...state.roles, action.value];

        return {
          ...state,
          roles,
        };
      }

      default:
        return state;
    }
  }, initUserFormData);

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.name) {
      errorMsgs.push('nameIsRequired');
    }
    if (form.email && !isEmailValid(form.email)) {
      errorMsgs.push('invalidEmail');
    }
    if (form.nif && !isNifValid(form.nif)) {
      errorMsgs.push('nifIsNotValid');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
