import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Information } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { RelatedPartyEntityType } from '../../../../../api';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../../libs/ui/atoms/src/lib/appButton';

interface ResetToDefaultConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  entityType: RelatedPartyEntityType;
}

export const ResetToDefaultConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  entityType,
}: ResetToDefaultConfirmModalProps) => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('brand.800', 'white');

  return (
    <Modal size={'md'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent color={textColor} borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalHeader>{t('relatedParties.resetToDefault')}</ModalHeader>
        <ModalCloseButton top={'6'} right="4" />
        <ModalBody>
          <Flex flexDirection={'column'} gap={'16px'} alignItems={'center'}>
            <Information w="64px" h="64px" color={textColor} />

            <Text fontSize="20px" fontWeight={'bold'} color={textColor} textAlign="center" pb={'16px'}>
              {entityType === RelatedPartyEntityType.BUYER
                ? t('relatedParties.thisWillResetBuyersIntoDefault')
                : t('relatedParties.thisWillResetSellersIntoDefault')}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          <AppButton
            buttonType={AppButtonType.MAIN}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
            title={t('cancel')}
            onClick={onClose}
          />
          <AppButton buttonType={AppButtonType.MAIN} title={t('ok')} onClick={onConfirm} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
