import { RadioGroup, Stack, Radio, StyleProps } from '@chakra-ui/react';

export interface RadioButtonItem<T = string> {
  value: T;
  label: string;
}

export interface RadioButtonProps<T = string> extends StyleProps {
  items: RadioButtonItem[];
  defaultValue?: T;
  value?: T;
  onChange: (value: T) => void;
}

export const RadioButton = <T,>({ items, value, defaultValue, onChange, ...styles }: RadioButtonProps<T>) => {
  return (
    <RadioGroup p="2px" onChange={e => onChange(e as T)} defaultValue={defaultValue as string} value={value as string}>
      <Stack spacing={5} direction="row" {...styles}>
        {items.map(item => (
          <Radio key={item.value} colorScheme="green" value={item.value}>
            {item.label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};
