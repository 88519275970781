import { api } from './api';
import { Submission, SubmissionRelatedPartyStatus } from './submission';
import { UniversalDocument } from './universal-document';

export enum RelatedPartyEntityType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export enum RelatedPartyRelationType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
  HEIR = 'HEIR',
}

export enum CivilStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum MarriageRegime {
  SEPARATION_OF_ASSETS = 'SEPARATION_OF_ASSETS',
  COMMUNION_OF_ACQUIRED = 'COMMUNION_OF_ACQUIRED',
  GENERAL_COMMUNION = 'GENERAL_COMMUNION',
}

export enum RelatedPartyAge {
  MAIOR = 'MAIOR',
  MENOR = 'MENOR',
}

export enum RelatedPartyIDDocumentType {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
}

export interface RelatedParty {
  id: string;
  jobId: string;
  relatedPartyId?: string;

  entityType: RelatedPartyEntityType;
  relationType: RelatedPartyRelationType;

  name: string;
  email?: string;
  nif?: string;
  address?: string;
  phone?: string;

  civilStatus?: CivilStatus;
  marriageRegime?: MarriageRegime;
  marriedTo?: string;

  heirName?: string | null;
  heirNif?: string | null;

  age?: RelatedPartyAge;
  nacionalidade?: string;
  placeOfBirthFreguesia?: string;
  placeOfBirthConcelho?: string;
  idDocumentType?: RelatedPartyIDDocumentType;
  idDocumentNumber?: string;
  idDocumentIssuanceAuthority?: string;
  idDocumentIssueDate?: Date;
  idDocumentExpiryDate?: Date;

  // Company fields
  corpAddress?: string;
  corpAddressParish?: string;
  corpAddressMunicipality?: string;
  corpAddressPostalCode?: string;
  corpAddressDistrict?: string;
  corpCapital?: string;
  // only for embedded into company
  corpRepAuthority?: string;

  isFiador?: boolean;
  hasCpcCode?: boolean;

  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;

  nestedRelatedParties?: RelatedParty[];

  submission?: Submission<SubmissionRelatedPartyStatus>;
  documents?: UniversalDocument[];
}

export interface RelatedPartyCreateDto {
  jobId: string;
  relatedPartyId?: string;

  entityType: RelatedPartyEntityType;
  relationType: RelatedPartyRelationType;

  name?: string;
  email?: string;
  nif?: string;
  address?: string;
  phone?: string;

  civilStatus?: CivilStatus;
  marriageRegime?: MarriageRegime;
  marriedTo?: string;

  heirName?: string | null;
  heirNif?: string | null;

  // Person fields
  age?: RelatedPartyAge;
  nacionalidade?: string;
  placeOfBirthFreguesia?: string;
  placeOfBirthConcelho?: string;
  idDocumentType?: RelatedPartyIDDocumentType;
  idDocumentNumber?: string;
  idDocumentIssuanceAuthority?: string;
  idDocumentIssueDate?: Date;
  idDocumentExpiryDate?: Date;
  // only for embedded into company
  corpRepAuthority?: string;

  // Company fields
  corpAddress?: string;
  corpAddressParish?: string;
  corpAddressMunicipality?: string;
  corpAddressDistrict?: string;
  corpAddressPostalCode?: string;
  corpCapital?: string;

  isFiador?: boolean;
}

export interface RelatedPartyUpdateDto {
  id: string;

  name?: string;
  email?: string;
  nif?: string;
  address?: string;
  phone?: string;

  civilStatus?: CivilStatus;
  marriageRegime?: MarriageRegime;
  marriedTo?: string;

  heirName?: string | null;
  heirNif?: string | null;

  // Person fields
  age?: RelatedPartyAge;
  nacionalidade?: string;
  placeOfBirthFreguesia?: string;
  placeOfBirthConcelho?: string;
  idDocumentType?: RelatedPartyIDDocumentType;
  idDocumentNumber?: string;
  idDocumentIssuanceAuthority?: string;
  idDocumentIssueDate?: Date;
  idDocumentExpiryDate?: Date;
  // only for embedded into company
  corpRepAuthority?: string;

  // Company fields
  corpAddress?: string;
  corpAddressParish?: string;
  corpAddressMunicipality?: string;
  corpAddressDistrict?: string;
  corpCapital?: string;

  isFiador?: boolean;
}

export interface RelatedPartiesFilter {
  search?: string;
  entityType?: RelatedPartyEntityType;
}

export enum RelatedPartyDocumentExtractType {
  ID_CARD = 'ID_CARD',
  ID_PASSPORT = 'ID_PASSPORT',
  ID_RESIDENCE_PERMIT = 'ID_RESIDENCE_PERMIT',
  HERANCA = 'HERANCA',
}

export interface RelatedPartySubmitExtractDto {
  id: string;
  submitType: RelatedPartyDocumentExtractType;
}

export interface RelatedPartySubmitByCodeDto {
  id: string;
  code: string;
  documentId: string;
}

const relatedPartyApi = api.injectEndpoints({
  endpoints: build => ({
    getJobParties: build.query<RelatedParty[], { jobId: string; filter: RelatedPartiesFilter }>({
      query: ({ jobId, filter }) => ({ url: `/related-party/${jobId}`, params: filter }),
    }),
    initJobParties: build.mutation<RelatedParty[], { jobId: string }>({
      query: ({ jobId }) => ({ url: `/related-party/init`, method: 'POST', body: { jobId } }),
    }),
    createRelatedParty: build.mutation<RelatedParty, RelatedPartyCreateDto>({
      query: data => ({ url: `/related-party`, method: 'POST', body: { ...data } }),
    }),
    updateRelatedParty: build.mutation<RelatedParty, RelatedPartyUpdateDto>({
      query: data => ({ url: `/related-party`, method: 'PUT', body: { ...data } }),
    }),
    deleteRelatedParty: build.mutation<RelatedParty, { id: string }>({
      query: ({ id }) => ({ url: `/related-party/${id}`, method: 'DELETE' }),
    }),
    resetRelatedParty: build.mutation<RelatedParty, { jobId: string; entityType: RelatedPartyEntityType }>({
      query: ({ jobId, entityType }) => ({
        url: `/related-party/reset`,
        method: 'POST',
        body: { jobId, entityType },
      }),
    }),
    submit: build.mutation<RelatedParty, RelatedPartySubmitExtractDto>({
      query: data => ({ url: `/related-party/submit`, method: 'POST', body: data }),
    }),
    submitCPC: build.mutation<RelatedParty, RelatedPartySubmitByCodeDto>({
      query: data => ({ url: `/related-party/submitCPC`, method: 'POST', body: data }),
    }),
    clearSubmission: build.mutation<RelatedParty, { id: string }>({
      query: ({ id }) => ({ url: `/related-party/clear-submission`, method: 'POST', body: { id } }),
    }),
  }),
});

export const {
  useLazyGetJobPartiesQuery,
  useGetJobPartiesQuery,
  useInitJobPartiesMutation,
  useCreateRelatedPartyMutation,
  useUpdateRelatedPartyMutation,
  useDeleteRelatedPartyMutation,
  useResetRelatedPartyMutation,
  useSubmitMutation: useRelatedPartySubmitMutation,
  useClearSubmissionMutation: useRelatedPartyClearSubmissionMutation,
  useSubmitCPCMutation: useRelatedPartySubmitByCodeMutation,
  endpoints: RelatedPartyEndpoints,
} = relatedPartyApi;
