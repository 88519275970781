import { IconType } from 'react-icons';
import { MdCancel, MdCheckCircle, MdErrorOutline, MdOutlineError } from 'react-icons/md';
import { SubmissionSigningStatus } from '../../../../api';
import { ReloadIcon } from '../../../../components/icons/Icons';

export const iconColorBySigningStatus: Record<SubmissionSigningStatus, string | undefined> = {
  [SubmissionSigningStatus.PREPARING]: undefined,
  [SubmissionSigningStatus.READY_TO_SEND]: undefined,
  [SubmissionSigningStatus.SENT]: 'green.400',
  [SubmissionSigningStatus.SUCCESS]: 'green.400',
  [SubmissionSigningStatus.REJECT]: 'red.500',
  [SubmissionSigningStatus.ERROR]: 'red.500',
  [SubmissionSigningStatus.NOT_SIGNED]: 'red.500',
};

export const iconColorSubmissionDraft = 'grey';

export const iconBySubmissionSigningStatus: Record<SubmissionSigningStatus, IconType> = {
  [SubmissionSigningStatus.PREPARING]: ReloadIcon as IconType,
  [SubmissionSigningStatus.READY_TO_SEND]: ReloadIcon as IconType,
  [SubmissionSigningStatus.SENT]: ReloadIcon as IconType,
  [SubmissionSigningStatus.SUCCESS]: MdCheckCircle,
  [SubmissionSigningStatus.REJECT]: MdErrorOutline,
  [SubmissionSigningStatus.ERROR]: MdOutlineError,
  [SubmissionSigningStatus.NOT_SIGNED]: MdErrorOutline,
};

export const iconSubmissionDraft = MdCancel;

export const signingStatusTranslateBySigningStatus: Record<SubmissionSigningStatus, string> = {
  [SubmissionSigningStatus.PREPARING]: 'pages.signings.signingStatuses.preparing',
  [SubmissionSigningStatus.READY_TO_SEND]: 'pages.signings.signingStatuses.readyToSend',
  [SubmissionSigningStatus.SENT]: 'pages.signings.signingStatuses.sent',
  [SubmissionSigningStatus.SUCCESS]: 'pages.signings.signingStatuses.signed',
  [SubmissionSigningStatus.REJECT]: 'pages.signings.signingStatuses.declined',
  [SubmissionSigningStatus.ERROR]: 'pages.signings.signingStatuses.error',
  [SubmissionSigningStatus.NOT_SIGNED]: 'pages.signings.signingStatuses.notSigned',
};

export const signingStatusTranslatedDraft = 'pages.signings.signingStatuses.draft';
