import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Job,
  JobDocument,
  JobDocumentsPrivateEndpoints,
  JobDocumentWithJobId,
  JobPrivateEndpoints,
  JobPublic,
  JobResult,
  JobWithDocumentsAndResult,
} from '../api';
import {
  ItemAddManyAction,
  itemAddManyReducer,
  ItemUpsertManyAction,
  ItemRemoveManyAction,
  itemUpsertManyReducer,
  itemRemoveManyReducer,
  ItemUpdateAction,
  itemUpdateReducer,
  ItemUpdateManyAction,
  itemUpdateManyReducer,
} from './list';
import { RootState } from './index';
import { SortingState } from '@tanstack/react-table';
import { createDebouncedAsyncThunk } from './debounce';

export interface JobState {
  jobsPageCount: number;
  jobsTotalCount: number;
  jobsSorting: SortingState;
  jobs: Job[];
  /**
   * jobId -> documents
   */
  jobDocuments: Record<string, JobDocument[]>;
  /**
   * jobId -> results
   */
  jobResults: Record<string, JobResult>;
  selectedJob: JobWithDocumentsAndResult | JobPublic | null;
}

const jobInitialState: JobState = {
  jobsPageCount: 0,
  jobsTotalCount: 0,
  jobsSorting: [],
  jobs: [],
  jobDocuments: {},
  jobResults: {},
  selectedJob: null,
};

const matchDocuments = (state: JobState, action: PayloadAction<JobDocumentWithJobId>) => {
  const document = action.payload;

  const jobState: JobState = jobSlice.reducer(
    state,
    upsertJobDocumentsByJobId({ jobId: document.jobId, items: [document], changePositionForUpdated: true }),
  );
  return jobState;
};
const matchDocumentsAfterDelete = (state: JobState, action: PayloadAction<JobDocumentWithJobId>) => {
  const document = action.payload;

  const jobState: JobState = jobSlice.reducer(
    state,
    deleteJobDocumentByJobId({ jobId: document.jobId, ids: [document.id] }),
  );

  return jobState;
};
const matchDocumentsAfterUpdate = (state: JobState, action: PayloadAction<JobDocumentWithJobId>) => {
  const document = action.payload;

  const jobState: JobState = jobSlice.reducer(
    state,
    updateJobDocumentByJobId({ item: document, jobId: document.jobId }),
  );
  return jobState;
};
const matchDocumentsMany = (state: JobState, action: PayloadAction<JobDocumentWithJobId[]>) => {
  const documents = action.payload;

  const jobId = documents.find(doc => doc.jobId)?.jobId;
  if (!jobId) return;

  const jobState: JobState = jobSlice.reducer(
    state,
    upsertJobDocumentsByJobId({ items: documents, jobId, replaceOldWithNew: true }),
  );
  return jobState;
};

const matchJobDocumentsMany = (state: JobState, action: PayloadAction<JobDocumentWithJobId[]>) => {
  const documents = action.payload;

  const jobId = documents.find(doc => doc.jobId)?.jobId;
  if (!jobId) return;

  const jobState: JobState = jobSlice.reducer(state, replaceJobDocumentsByJobId({ items: documents, jobId }));
  return jobState;
};

const matchJobs = (
  state: JobState,
  action: PayloadAction<{ data?: Job[] | null; totalCount: number; pageCount: number }>,
) => {
  const jobState: JobState = jobSlice.reducer(
    state,
    upsertJobs({ items: [...(action.payload.data ?? [])], changePositionForUpdated: true, addAtEnd: true }),
  );
  return { ...jobState, jobsPageCount: action.payload.pageCount, jobsTotalCount: action.payload.totalCount };
};
const matchJob = (state: JobState, action: PayloadAction<Job>) => {
  // Don't inline because typescript problem
  const jobState: JobState = jobSlice.reducer(
    state,
    upsertJobs({ items: [action.payload], changePositionForUpdated: true }),
  );
  return jobState;
};
const matchJobDocuments = (state: JobState, action: PayloadAction<JobWithDocumentsAndResult>) => {
  const job = action.payload;

  if (job.documents && job.documents?.length >= 0) {
    const jobState: JobState = jobSlice.reducer(
      state,
      upsertJobDocumentsByJobId({ jobId: job.id, items: job.documents }),
    );
    return jobState;
  }
  return state;
};
const matchJobResult = (state: JobState, action: PayloadAction<JobWithDocumentsAndResult>) => {
  const job = action.payload;

  if (job.jobResult) {
    const jobState: JobState = jobSlice.reducer(
      state,
      upsertJobResultsByJobId({ jobId: job.id, jobResult: job.jobResult }),
    );
    return jobState;
  }
  return state;
};

const jobSlice = createSlice({
  name: 'job',
  initialState: jobInitialState,
  reducers: {
    upsertJobs: (state, action: PayloadAction<ItemUpsertManyAction<Job>>) => ({
      ...state,
      jobs: itemUpsertManyReducer(state.jobs, action.payload),
    }),
    addJobs: (state, action: PayloadAction<ItemAddManyAction<Job>>) => ({
      ...state,
      jobs: itemAddManyReducer(state.jobs, action.payload),
    }),
    upsertJobsSorting: (state, action: PayloadAction<{ jobsSorting: SortingState }>) => ({
      ...state,
      jobsSorting: action.payload.jobsSorting,
    }),
    replaceJobDocumentsByJobId: (state, action: PayloadAction<{ items: JobDocument[]; jobId: string }>) => ({
      ...state,
      jobDocuments: { ...state.jobDocuments, [action.payload.jobId]: action.payload.items },
    }),
    upsertJobDocumentsByJobId: (
      state,
      action: PayloadAction<ItemUpsertManyAction<JobDocument> & { jobId: string }>,
    ) => ({
      ...state,
      jobDocuments: {
        ...state.jobDocuments,
        [action.payload.jobId]: itemUpsertManyReducer(state.jobDocuments[action.payload.jobId], action.payload),
      },
    }),
    updateJobDocumentByJobId: (state, action: PayloadAction<ItemUpdateAction<JobDocument> & { jobId: string }>) => ({
      ...state,
      jobDocuments: {
        ...state.jobDocuments,
        [action.payload.jobId]: itemUpdateReducer(state.jobDocuments[action.payload.jobId], action.payload),
      },
    }),
    updateJobDocumentsByJobId: (
      state,
      action: PayloadAction<ItemUpdateManyAction<JobDocument> & { jobId: string }>,
    ) => ({
      ...state,
      jobDocuments: {
        ...state.jobDocuments,
        [action.payload.jobId]: itemUpdateManyReducer(state.jobDocuments[action.payload.jobId], action.payload),
      },
    }),
    deleteJobDocumentByJobId: (state, action: PayloadAction<ItemRemoveManyAction & { jobId: string }>) => ({
      ...state,
      jobDocuments: {
        ...state.jobDocuments,
        [action.payload.jobId]: itemRemoveManyReducer(state.jobDocuments[action.payload.jobId], {
          ids: action.payload.ids,
        }),
      },
    }),
    upsertJobResultsByJobId: (state, action: PayloadAction<{ jobId: string; jobResult: JobResult }>) => ({
      ...state,
      jobResults: {
        ...state.jobResults,
        [action.payload.jobId]: action.payload.jobResult,
      },
    }),
    jobSelected: (state, { payload }: PayloadAction<{ job: JobWithDocumentsAndResult | JobPublic }>) => ({
      ...state,
      selectedJob: payload.job,
    }),
    jobUnselected: state => ({ ...state, selectedJob: null }),
    tabChanged: state => ({ ...state, jobs: [], jobsPageCount: 0, jobsTotalCount: 0 }),
    organizationChanged: state => ({ ...state, jobs: [], jobsPageCount: 0, jobsTotalCount: 0 }),
    sortChanged: state => ({ ...state, jobs: [], jobsPageCount: 0, jobsTotalCount: 0 }),
    jobArchived: (state, { payload }: PayloadAction<{ job: Job }>) => ({
      ...state,
      jobs: state.jobs.filter(j => j.id !== payload.job.id),
    }),
    jobUnarchived: (state, { payload }: PayloadAction<{ job: Job }>) => ({
      ...state,
      jobs: state.jobs.filter(j => j.id !== payload.job.id),
    }),
    jobDeleted: (state, { payload }: PayloadAction<{ job: Job }>) => ({
      ...state,
      jobs: state.jobs.filter(j => j.id !== payload.job.id),
    }),
  },
  extraReducers: builder =>
    builder
      .addMatcher(JobPrivateEndpoints.getJobs.matchFulfilled, matchJobs)
      .addMatcher(JobPrivateEndpoints.getJob.matchFulfilled, matchJob)
      .addMatcher(JobPrivateEndpoints.getJob.matchFulfilled, matchJobDocuments)
      .addMatcher(JobPrivateEndpoints.getJob.matchFulfilled, matchJobResult)
      .addMatcher(JobPrivateEndpoints.newJob.matchFulfilled, matchJob)
      .addMatcher(JobPrivateEndpoints.newJob.matchFulfilled, matchJobDocuments)
      .addMatcher(JobPrivateEndpoints.newJob.matchFulfilled, matchJobResult)
      .addMatcher(JobPrivateEndpoints.createJob.matchFulfilled, matchJob)
      .addMatcher(JobPrivateEndpoints.createJob.matchFulfilled, matchJobDocuments)
      .addMatcher(JobPrivateEndpoints.createJob.matchFulfilled, matchJobResult)
      .addMatcher(JobPrivateEndpoints.updateJob.matchFulfilled, matchJob)
      .addMatcher(JobPrivateEndpoints.updateJob.matchFulfilled, matchJobDocuments)
      .addMatcher(JobPrivateEndpoints.updateJob.matchFulfilled, matchJobResult)
      .addMatcher(JobPrivateEndpoints.submitJob.matchFulfilled, matchJob)
      .addMatcher(JobPrivateEndpoints.submitJob.matchFulfilled, matchJobDocuments)
      .addMatcher(JobPrivateEndpoints.submitJob.matchFulfilled, matchJobResult)
      .addMatcher(JobDocumentsPrivateEndpoints.uploadJobDocument.matchFulfilled, matchDocuments)
      .addMatcher(JobDocumentsPrivateEndpoints.addCertidaoWithAccessCode.matchFulfilled, matchDocumentsMany)
      .addMatcher(JobDocumentsPrivateEndpoints.addCadernetaWithAccessCode.matchFulfilled, matchDocumentsMany)
      .addMatcher(JobDocumentsPrivateEndpoints.updateJobDocument.matchFulfilled, matchDocumentsAfterUpdate)
      .addMatcher(JobDocumentsPrivateEndpoints.deleteJobDocument.matchFulfilled, matchDocumentsAfterDelete)
      .addMatcher(JobDocumentsPrivateEndpoints.getJobDocuments.matchFulfilled, matchDocumentsMany)
      .addMatcher(JobDocumentsPrivateEndpoints.getAllJobDocuments.matchFulfilled, matchJobDocumentsMany),
});

export const { reducer: jobReducer, reducerPath: jobReducerPath } = jobSlice;
export const {
  upsertJobs,
  addJobs,
  upsertJobDocumentsByJobId,
  updateJobDocumentByJobId,
  updateJobDocumentsByJobId,
  replaceJobDocumentsByJobId,
  upsertJobResultsByJobId,
  upsertJobsSorting,
  deleteJobDocumentByJobId,
  jobSelected,
  tabChanged,
  organizationChanged,
  jobUnarchived,
  sortChanged,
  jobArchived,
  jobUnselected,
  jobDeleted,
} = jobSlice.actions;

export const selectJob = createSelector(
  [(state: RootState) => state.job.jobs, (_, jobId: string) => jobId],
  (jobs, jobId) => jobs.find(job => job.id === jobId) ?? null,
);

export const selectJobDocuments = createSelector(
  [(state: RootState) => state.job.jobDocuments, (_, jobId: string) => jobId],
  (jobDocuments, jobId) => jobDocuments[jobId] || [],
);

export const updateJobMutation = createDebouncedAsyncThunk<Job, { id: string; name?: string; description?: string }>(
  jobSlice.reducerPath + '/update',
  async (arg, thunkAPI) => await thunkAPI.dispatch(JobPrivateEndpoints.updateJob.initiate(arg)).unwrap(),
  1000,
);
