import { Button, Flex, StyleProps, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuCheckCircle, LuCircle } from 'react-icons/lu';
import { SigningType } from '../../../../api/signing';

export interface SignerSigningTypeSelectProps extends StyleProps {
  isDisabled?: boolean;
  signingType: SigningType;
  onChange: (value: SigningType) => void;
}

export const SignerSigningTypeSelect = (props: SignerSigningTypeSelectProps) => {
  const { isDisabled, signingType, onChange, ...styles } = props;

  const { t } = useTranslation();

  const btnTextColor = useColorModeValue('_gray.500', 'whiteAlpha.600');
  const btnTextColorActive = useColorModeValue('blue.500', 'white');

  return (
    <Flex mr="24px" gap="16px" {...styles}>
      {(!isDisabled || (isDisabled && signingType === SigningType.SMS)) && (
        <Button
          minW={'fit-content'}
          fontSize={'14px'}
          borderRadius={'10px'}
          bg={signingType !== SigningType.SMS ? 'transparent' : undefined}
          color={signingType === SigningType.SMS ? btnTextColorActive : btnTextColor}
          borderColor={btnTextColor}
          border={signingType !== SigningType.SMS ? '1px solid' : 'none'}
          height="40px"
          leftIcon={signingType === SigningType.SMS ? <LuCheckCircle /> : <LuCircle />}
          onClick={e => {
            e.stopPropagation();
            onChange(SigningType.SMS);
          }}
        >
          {t('signer.signingType.sms')}
        </Button>
      )}

      {!isDisabled && signingType === SigningType.SMS && (
        <Text
          maxH="42px"
          fontSize={'14px'}
          lineHeight="16px"
          display={'flex'}
          alignItems="center"
          whiteSpace={'break-spaces'}
        >
          {t('signer.The signatory must confirm their acceptance only by SMS code')}
        </Text>
      )}

      {(!isDisabled || (isDisabled && signingType === SigningType.MOBILE_KEY_OR_CITIZEN_CARD)) && (
        <Button
          minW={'fit-content'}
          borderRadius={'10px'}
          height="40px"
          fontSize={'14px'}
          borderColor={btnTextColor}
          border={signingType !== SigningType.MOBILE_KEY_OR_CITIZEN_CARD ? '1px solid' : 'none'}
          bg={signingType !== SigningType.MOBILE_KEY_OR_CITIZEN_CARD ? 'transparent' : undefined}
          color={signingType === SigningType.MOBILE_KEY_OR_CITIZEN_CARD ? btnTextColorActive : btnTextColor}
          leftIcon={signingType === SigningType.MOBILE_KEY_OR_CITIZEN_CARD ? <LuCheckCircle /> : <LuCircle />}
          onClick={e => {
            e.stopPropagation();
            onChange(SigningType.MOBILE_KEY_OR_CITIZEN_CARD);
          }}
        >
          {t('signer.signingType.mobileKeyOrCitizenCard')}
        </Button>
      )}

      {!isDisabled && signingType === SigningType.MOBILE_KEY_OR_CITIZEN_CARD && (
        <Text
          maxH="42px"
          fontSize={'14px'}
          lineHeight="16px"
          display={'flex'}
          alignItems="center"
          whiteSpace={'break-spaces'}
        >
          {t(
            'signer.The signatory must confirm their acceptance by digital signature SMS code acceptance is not available',
          )}
        </Text>
      )}
    </Flex>
  );
};
