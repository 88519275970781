import {
  Button,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiMiniLifebuoy } from 'react-icons/hi2';
import WhatsAppQR from '../../assets/img/WhatsApp-qr.png';
import WhatsAppButton from 'assets/img/WhatsAppButtonGreenLarge.svg';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

export const WhatsAppHelpButton = () => {
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        w={{ md: 'auto' }}
        h={{ md: 'auto' }}
        alignItems="center"
        flexDirection="row"
        p={'14px'}
        bg={menuBg}
        borderRadius="30px"
        minW="unset"
        minH="unset"
        onClick={() => setIsOpen(true)}
      >
        <Icon h="18px" w="18px" color={navbarIcon} as={HiMiniLifebuoy} />
      </Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent w={'unset'} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalBody p="24px 1px">
            <Flex justifyContent={'center'} flexDirection={'column'} gap={'8px'} alignItems={'center'}>
              <Text>{t('help.description')}</Text>
              <Image src={WhatsAppQR}></Image>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <Image
              style={{ cursor: 'pointer', borderRadius: '30px' }}
              alt="Chat on WhatsApp"
              src={WhatsAppButton}
              onClick={() => window.open('https://wa.me/351911798471')}
            />
            <AppButton buttonType={AppButtonType.PRIMARY_MAIN} title={t('close')} onClick={() => setIsOpen(false)} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
