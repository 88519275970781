import { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { useTypedSelector } from 'store';
import { auth0Api } from 'api/auth0';
import { useLazyMePrivateQuery } from 'api';
import Card from 'components/card/Card';
import { ChangePasswordModal } from './components/ChangePasswordModal';

import BannerImg from 'assets/img/profile-banner.jpg';
import Banner from '../../../components/common/profile-banner';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../libs/ui/atoms/src/lib/appButton';

export default function Profile() {
  const { t } = useTranslation();

  const { logout } = useAuth0();

  const [getMe] = useLazyMePrivateQuery();

  const organizationId = useTypedSelector(state => state.auth.organizationId);
  const auth = useTypedSelector(state => state.auth);

  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState<boolean>(false);
  const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState<boolean>(false);
  const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);

  const currentOrganization = auth.user?.organizations?.find(org => org.id === organizationId);

  useEffect(() => {
    getMe({}, false);
  }, []);

  const getBalance = () => {
    if (currentOrganization) {
      return currentOrganization.balanceEur;
    }

    return 0;
  };

  const changePassword = async () => {
    if (!auth.userAuth?.email) {
      console.error('No email found');
      return;
    }

    setPasswordChangeLoading(true);

    const isSuccess = await auth0Api.changePassword(auth.userAuth.email);

    setPasswordChangeLoading(false);
    setIsPasswordChangeSuccess(isSuccess);
    setIsPasswordChangeModalOpen(true);
  };

  return (
    <>
      <Box>
        <Card mb={{ base: '0px', lg: '20px' }} alignItems="stretch" position="static">
          <Banner
            banner={BannerImg}
            avatar={auth.userAuth?.picture}
            mainTitle={auth.user?.name || ''}
            subtitle={currentOrganization?.name || t('personal')}
            jobs={auth.user?.jobCount || 0}
            balance={getBalance()}
            files={auth.user?.documentCount || 0}
          />
          <Flex
            gap={{ base: '12px', md: '24px' }}
            justifyContent="center"
            alignItems="center"
            direction={{ base: 'column', md: 'row' }}
          >
            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={changePassword}
              isLoading={passwordChangeLoading}
              loadingText={t('changePassword')}
              title={t('changePassword')}
            />

            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={() => logout()}
              title={t('logout')}
              colorSchemes={[AppButtonColorScheme.INFO]}
            />
          </Flex>
        </Card>
      </Box>

      <ChangePasswordModal
        isOpen={isPasswordChangeModalOpen}
        onClose={() => setIsPasswordChangeModalOpen(false)}
        isSuccess={isPasswordChangeSuccess}
      />
    </>
  );
}
