import { Icon } from '@chakra-ui/react';
import { JobListPage } from './views/admin/job/job-list.page';
import { Cube, Home, OfficeBag, Profile as ProfileIcon } from 'libs/ui/atoms/src/lib/icons/icons';
import { JobResultPrivatePage, JobResultPublicPage } from './views/job-result/job-result.page';
import { JobDetailPage } from './views/admin/job/job-detail.page';
import { JobAddPage } from './views/admin/job/job-add.page';
import Profile from 'views/admin/profile';
import { AddContractsPage } from 'views/admin/job/add-contracts.page';
import { SigningListPage } from './views/admin/signing/signing-list.page';
import { EditIcon } from '@chakra-ui/icons';
import { SigningDetailPage } from './views/admin/signing/signing-detail.page';
import Company from './views/admin/company';
import { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { UserRole } from './api';
import HomePage from './views/admin/home';
import { SignButton } from './components/signButton/SignButton';
import { SigningConfirmPage } from './views/admin/signing/signing-confirm.page';

export const routesAuth: RoutesType[] = [
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: SignInCentered,
  // },
];

export const routesPublic: RoutesType[] = [
  {
    name: 'Job Result',
    translatedName: 'routes.jobResult',
    layout: '/',
    path: '/:jobId/:language?',
    icon: <Icon as={ProfileIcon} width="20px" height="20px" color="inherit" />,
    secondary: true,
    component: JobResultPublicPage,
  },
];

export const useAppRoutes = () => {
  const organizationId = useTypedSelector(state => state.auth.organizationId);
  const user = useTypedSelector(state => state.auth.user);

  const routes = useMemo<RoutesType[]>(
    () => [
      {
        name: 'Home',
        translatedName: 'routes.homePage',
        layout: '/',
        path: '/home',
        icon: <Icon as={Home} width="18px" height="21px" color="inherit" />,
        component: HomePage,
      },

      {
        name: 'Add Job',
        translatedName: 'routes.addJob',
        layout: '/',
        path: '/jobs/add',
        icon: <Icon as={Cube} width="22px" height="22px" color="inherit" />,
        component: JobAddPage,
        hideInSideMenu: true,
      },
      {
        name: 'Add contracts',
        translatedName: 'routes.contracts&parties',
        layout: '/',
        path: '/jobs/:jobId/contracts-and-parties',
        icon: <Icon as={Cube} width="22px" height="22px" color="inherit" />,
        component: AddContractsPage,
        hideInSideMenu: true,
      },
      {
        name: 'Edit Job',
        translatedName: 'routes.editJob',
        layout: '/',
        path: '/jobs/:jobId',
        icon: <Icon as={Cube} width="22px" height="22px" color="inherit" />,
        component: JobDetailPage,
        hideInSideMenu: true,
      },
      {
        name: 'Jobs',
        translatedName: 'routes.jobs',
        layout: '/',
        path: '/jobs',
        icon: <Icon as={Cube} width="22px" height="22px" color="inherit" />,
        component: JobListPage,
      },
      // {
      //   name: 'History',
      //   layout: '/',
      //   path: '/history',
      //   icon: <Icon as={History} width="20px" height="20px" color="inherit" />,
      //   component: HistoryPage,
      //   secondary: true,
      // },
      // {
      //   name: 'Templates',
      //   translatedName: 'routes.templates',
      //   layout: '/',
      //   path: '/templates',
      //   icon: <Icon as={Template} width="20px" height="20px" color="inherit" />,
      //   component: TemplatesPage,
      // },
      {
        name: 'Job Result',
        translatedName: 'routes.jobResult',
        layout: '/',
        path: '/job-result/:jobId',
        icon: <Icon as={ProfileIcon} width="20px" height="20px" color="inherit" />,
        secondary: true,
        component: JobResultPrivatePage,
        hideInSideMenu: true,
      },

      {
        name: 'Signings',
        translatedName: 'routes.signings',
        layout: '/',
        path: '/signings',
        icon: <Icon as={EditIcon} width="20px" height="20px" color="inherit" />,
        component: SigningListPage,
        customAction: <SignButton />,
      },
      {
        name: 'Signing',
        translatedName: 'routes.signing',
        layout: '/',
        path: '/signings/:signingId',
        icon: <Icon as={Cube} width="20px" height="20px" color="inherit" />,
        component: SigningDetailPage,
        hideInSideMenu: true,
      },
      {
        name: 'Signing confirm',
        translatedName: 'routes.signing',
        layout: '/',
        path: '/signings/:signingId/confirm',
        icon: <Icon as={Cube} width="20px" height="20px" color="inherit" />,
        component: SigningConfirmPage,
        hideInSideMenu: true,
      },
      {
        name: 'Profile',
        translatedName: 'routes.profile',
        layout: '/',
        path: '/profile',
        icon: <Icon as={ProfileIcon} width="22px" height="22px" color="inherit" />,
        component: Profile,
      },
      ...(user?.roles?.includes(UserRole.MASTER_USER)
        ? [
            {
              name: 'Company',
              translatedName: 'routes.company',
              layout: '/',
              path: '/company',
              icon: <Icon as={OfficeBag} width="20px" height="20px" color="inherit" />,
              component: Company,
              isHidden: !organizationId,
            },
          ]
        : []),
    ],
    [organizationId, user?.roles],
  );

  return routes;
};

export const adminCreateButtonRoute = '/jobs/add';
