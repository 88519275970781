import { Box, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import { useLazyGetSigningQuery, useSubmitSigningMutation, useUpdateSigningMutation } from '../../../api/signing';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTypedSelector } from '../../../store';
import { SigningDetailsWidget } from './widgets/SigningDetailsWidget';

export const SigningDetailPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { signingId } = useParams<{ signingId: string }>();
  const selectedSigning = useTypedSelector(state => state.signing.selectedSigning ?? undefined);
  const signing = signingId === selectedSigning?.id ? selectedSigning : undefined;

  const [fetchSigning, { data, isLoading }] = useLazyGetSigningQuery({
    pollingInterval: signing?.submission?.status === SubmissionSigningStatus.PREPARING ? 700 : 0,
  });

  const [submitSigning, { isLoading: isSubmitting }] = useSubmitSigningMutation();
  const [updateSigning, { isLoading: isloadingUpdate }] = useUpdateSigningMutation();

  const [name, setName] = useState<string | undefined>(undefined);
  const debouncedName = useDebounce(name, { delay: 300 });

  const load = useCallback(() => {
    fetchSigning({ signingId: signingId ?? '' });
  }, [fetchSigning, signingId]);

  useEffect(() => {
    setName(signing?.job?.name ?? signing?.name ?? '');
  }, [signing]);

  useEffect(() => {
    load();
  }, [load]);

  const onSubmit = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      await submitSigning({ id: signingId }).unwrap();
      load();
    } catch (error) {
      toast({
        title: t('error'),
        description: (error as { data: Error }).data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [load, signingId, submitSigning, t, toast]);

  const onUpdateName = useCallback(
    async (name: string) => {
      if (isloadingUpdate) {
        return;
      }

      if (!signingId) {
        throw new Error('Invalid application state! Signing ID is not defined');
      }

      try {
        await updateSigning({ id: signingId, name }).unwrap();
        load();
      } catch (error) {
        toast({
          title: t('error'),
          description: (error as { data: Error }).data?.message,
          status: 'error',
          duration: 3000,
        });
      }
    },
    [isloadingUpdate, load, signingId, t, toast, updateSigning],
  );

  useEffect(() => {
    const initialName = signing?.name ?? '';
    if (!debouncedName || initialName === debouncedName) {
      return;
    }

    onUpdateName(debouncedName);
  }, [debouncedName, onUpdateName, signing?.name]);

  useEffect(() => {
    if (signing?.submission?.status === SubmissionSigningStatus.READY_TO_SEND) {
      navigate(`/signings/${signingId}/confirm`, { replace: true });
    }
  }, [navigate, signing?.submission?.status, signingId]);

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }
  if (!signing) {
    return <Box>...{t('pages.signing.signingFetchError')}</Box>;
  }

  return (
    <SigningDetailsWidget
      signing={signing}
      name={name}
      onDocumentsChanged={() => {
        fetchSigning({ signingId: signing.id });
      }}
      onSubmit={onSubmit}
      isLoadingSubmit={isSubmitting}
      onSingersCountChanged={() => load()}
      onNameChanged={e => setName(e)}
    ></SigningDetailsWidget>
  );
};
