import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Copy, Share } from 'libs/ui/atoms/src';
import { useTypedSelector } from 'store';
import { useSendJobResultEmailMutation } from '../../api';
import { useMatch } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

export const JobShareButton = () => {
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [sendMail, { isLoading, error, data }] = useSendJobResultEmailMutation();

  const selectedJob = useTypedSelector(state => state.job.selectedJob);
  const [url, setUrl] = useState('');
  const [receiverEmail, setReceiverEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  useEffect(() => {
    setUrl(
      selectedJob?.publicId
        ? `${window.location.protocol}//${window.location.host}/r/${selectedJob?.publicId}/${i18n.language}`
        : '',
    );
  }, [selectedJob, i18n.language]);

  const linkBgColor = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const linkColor = useColorModeValue('navy.750', 'white');

  const copyUrlToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(url);
    toast({ title: t('copiedToClipboard'), status: 'info', duration: 1500 });
  }, [url]);

  const sendViaEmail = useCallback(async () => {
    if (!isEmailValid) {
      toast({ title: t('invalidEmail'), status: 'error', duration: 2000 });
      return;
    }
    try {
      const response = await sendMail({ email: receiverEmail, link: url, locale: i18n.language.toUpperCase() });
      if ((response as any)['error']) {
        toast({ title: t('sendEmailError'), status: 'error', duration: 1500 });
      } else {
        toast({ title: t('sentViaEmail'), status: 'info', duration: 1500 });
      }
    } catch (error) {
      toast({ title: t('sendEmailError'), status: 'error', duration: 1500 });
    }
    setReceiverEmail('');
  }, [receiverEmail, setReceiverEmail, isEmailValid]);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const handleEmailChange = useCallback(
    (value: string) => {
      const isValid = value?.match(isValidEmail);
      setIsEmailValid(!!isValid);
      setReceiverEmail(value);
    },
    [setReceiverEmail, setIsEmailValid],
  );

  const isResultPage = useMatch('/job-result/:jobId');
  const isPublicPage = useMatch('/r/:jobId/:language?');

  return (
    <>
      <Modal size={'lg'} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="16" padding={'2'} paddingBottom="6">
          <ModalHeader>{t('pages.details.shareResult')}</ModalHeader>
          <ModalCloseButton top={'6'} right="4" />
          <ModalBody>
            <Box
              borderRadius={'8'}
              bg={linkBgColor}
              paddingStart={'4'}
              paddingTop="2"
              paddingEnd="2"
              paddingBottom="2"
              gap={'4'}
              display="flex"
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Link color={linkColor} href={url} target="_blank">
                {url}
              </Link>

              <AppButton
                buttonType={AppButtonType.ICON}
                isLoading={isLoading}
                onClick={copyUrlToClipboard}
                children={<Copy boxSize={5} />}
              />
            </Box>

            <FormControl marginTop="16px" isRequired={false}>
              <FormLabel mb="0">{t('pages.details.shareViaEmail')}</FormLabel>
              <Flex gap="12px">
                <Input
                  type="email"
                  flex={2}
                  placeholder={t('email')}
                  value={receiverEmail}
                  onChange={event => handleEmailChange(event.target.value)}
                />

                <AppButton
                  buttonType={AppButtonType.PRIMARY}
                  isDisabled={!receiverEmail}
                  onClick={sendViaEmail}
                  title={t('pages.details.share')}
                />
              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      {url && (isResultPage || isPublicPage) ? (
        <AppButton
          buttonType={AppButtonType.XSMALL}
          leftIcon={<Share boxSize={4} />}
          onClick={() => url && onOpen()}
          title={t('pages.details.share')}
        />
      ) : (
        <></>
      )}
    </>
  );
};
