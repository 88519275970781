import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useAppToast = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const showSuccess = useCallback(
    (title: string) => {
      toast({ title, status: 'success', duration: 1500 });
    },
    [toast],
  );

  const showError = useCallback(
    (description?: string) => {
      toast({ title: t('error'), description, status: 'error', duration: 5000, isClosable: true });
    },
    [toast, t],
  );

  const showApiError = useCallback(
    (apiError: unknown) => {
      showError(t((apiError as { data: Error })?.data?.message));
    },
    [showError, t],
  );

  const showWarning = useCallback(
    (title: string) => {
      toast({ title, status: 'warning', duration: 1500 });
    },
    [toast],
  );

  const showInfo = useCallback(
    (title: string) => {
      toast({ title, status: 'info', duration: 1500 });
    },
    [toast],
  );

  return {
    showInfo,
    showSuccess,
    showError,
    showApiError,
    showWarning,
  };
};
