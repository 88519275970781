import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'store';
import { Job } from 'api';
import { Link, useMatch } from 'react-router-dom';
import { DocumentOutFilledIcon } from '../icons/Icons';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

export const AddContractsButton = () => {
  const { t } = useTranslation();

  const job = useTypedSelector(state => state.job.selectedJob) as Job;

  const isResultPage = useMatch('/job-result/:jobId');

  return (
    <>
      {isResultPage && job?.id && (
        <Link to={`/jobs/${job.id}/contracts-and-parties`}>
          <AppButton
            buttonType={AppButtonType.PRIMARY}
            leftIcon={<DocumentOutFilledIcon color={'white'} w="24px" h="24px" />}
            title={t('pages.details.contracts&parties')}
          />
        </Link>
      )}
    </>
  );
};
