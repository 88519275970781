import { EditIcon } from '@chakra-ui/icons';
import { Box, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { Job, SubmissionSigningStatus } from '../../api';
import { useCreateSigningMutation } from '../../api/signing';
import { useTypedSelector } from '../../store';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

export interface JobSignButtonProps {
  isAccordionItem?: boolean;
}

export const JobSignButton = (props: JobSignButtonProps) => {
  const { isAccordionItem } = props;

  const { t } = useTranslation();
  const toast = useToast();

  const textColor = useColorModeValue('navy.750', 'white');
  const menuItemBg = useColorModeValue('gray.50', 'whiteAlpha.200');
  const menuItemHoveBg = useColorModeValue('gray.150', 'whiteAlpha.300');

  const isResultPage = useMatch('/job-result/:jobId');

  const navigate = useNavigate();

  const job = useTypedSelector(state => state.job.selectedJob) as Job;
  const signing = job?.signing;

  const [createSigning, { data, isLoading }] = useCreateSigningMutation();

  const handleClick = useCallback(async () => {
    if (
      !signing ||
      (signing?.submission?.status &&
        [SubmissionSigningStatus.SUCCESS, SubmissionSigningStatus.REJECT, SubmissionSigningStatus.ERROR].includes(
          signing.submission.status,
        ))
    ) {
      try {
        const result = await createSigning({ jobId: job.id }).unwrap();
        navigate(`/signings/${result?.id}`);
      } catch (error) {
        toast({
          title: t('error'),
          description: t('job.pages.details.signError'),
          status: 'error',
          duration: 1500,
        });
      }
    } else {
      navigate(`/signings/${signing.id}`);
    }
  }, [createSigning, navigate, signing, job, data, toast, t]);

  return (
    <>
      {job &&
        job.id &&
        (isAccordionItem ? (
          <Box
            cursor={'pointer'}
            borderRadius={'8px'}
            _hover={{ bg: menuItemHoveBg }}
            bg={menuItemBg}
            color={textColor}
            fontSize="14px"
            lineHeight={'14px'}
            fontWeight={500}
            p="12px"
            onClick={handleClick}
          >
            {t('pages.signing.toolbar.button')}
          </Box>
        ) : (
          isResultPage && (
            <AppButton
              buttonType={AppButtonType.PRIMARY}
              isLoading={isLoading}
              leftIcon={<EditIcon color={'white'} w="24px" h="24px" />}
              onClick={handleClick}
              title={t('pages.details.sign')}
            />
          )
        ))}
    </>
  );
};
