import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * This modal is displayed when the user tries to update document type to system only updatable types.
 */
export const ModalDocumentTypeValidationError = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('error')}
          </ModalHeader>
          <ModalBody p="24px 0">
            <Text fontSize="20px">{t('pages.details.unsupportedFile')}</Text>
          </ModalBody>
          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton buttonType={AppButtonType.PRIMARY_MAIN} onClick={onClose} title={t('close')} />
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
