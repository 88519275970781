import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { environment } from '../environments/environment';
import { api } from '../api';
import { authReducer, authReducerPath } from './auth';
import { jobReducer, jobReducerPath } from './job';
import { signingReducer, signingReducerPath } from './signing';
import { helpToolsReducer, helpToolsReducerPath } from './help-tools';
import { signersReducer, signersReducerPath } from './signer';

export const store = configureStore({
  devTools: !environment.production,
  reducer: {
    [authReducerPath]: authReducer,
    [jobReducerPath]: jobReducer,
    [signingReducerPath]: signingReducer,
    [helpToolsReducerPath]: helpToolsReducer,
    [signersReducerPath]: signersReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
