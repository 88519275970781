import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  callback: (accessCode: string) => void;
}

export const ModalCertidaoPerdialValidation: React.FC<Props> = ({ callback, isLoading, isOpen, onOpen, onClose }) => {
  const { t } = useTranslation();

  const { onClose: onCloseHandler } = useDisclosure({
    isOpen,
    onOpen,
    onClose: () => {
      handleReset();
      onClose();
    },
  });

  const [accessCode, setAccessCode] = useState<string>('');
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const initialRef = React.useRef<any>();
  const finalRef = React.useRef<any>();

  const handleReset = () => {
    setAccessCode('');
    setIsRequired(false);
  };

  const isValid = () => {
    return Boolean(accessCode);
  };

  const submitHandler = async () => {
    if (!isValid()) {
      setIsRequired(true);
      return;
    }

    callback(accessCode.trim());

    onCloseHandler();
    handleReset();
  };

  return (
    <>
      <AppButton
        buttonType={AppButtonType.MAIN}
        title={t('pages.details.addCertidaoPermanenteWithAccessCode')}
        isLoading={isLoading}
        onClick={onOpen}
      />

      <Modal size="lg" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseHandler}>
        <ModalOverlay />
        <ModalContent p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('pages.details.certidaoPredialValidation')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 0">
            <FormControl isRequired={isRequired}>
              <FormLabel>{t('pages.details.accessCode')}</FormLabel>
              <Input
                ref={initialRef}
                placeholder={t('code')}
                value={accessCode}
                onChange={event => setAccessCode(event.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton buttonType={AppButtonType.MAIN} onClick={submitHandler} title={t('pages.details.submit')} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
