import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { environment } from 'environments/environment';
import { ErrorInSync } from 'libs/ui/atoms/src';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from 'store';
import { selectJob } from 'store/job';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

// eslint-disable-next-line react-refresh/only-export-components
export enum DocumentsValidationErrorTypeEnum {
  CadernetaAccessCodeInvalid = 'CadernetaAccessCodeInvalid',
  CertidaoAccessCodeInvalid = 'CertidaoAccessCodeInvalid',
  FatalError = 'DocumentFatalError',
  RequiredDocumentsMissing = 'RequiredDocumentsMissing',
  TooManyDocuments = 'TooManyDocuments',
  CertidaoIsUnacceptable = 'CertidaoIsUnacceptable',
  CertidaoRegistoComercialAccessCodeInvalid = 'CertidaoRegistoComercialAccessCodeInvalid',
  CertidaoPropertyIdMismatch = 'CertidaoPropertyIdMismatch',
  NO_DATA_FOUND_ON_DOCUMENT = 'NO_DATA_FOUND_ON_DOCUMENT',
}

interface Props {
  jobId?: string;
  errorType: DocumentsValidationErrorTypeEnum | null;
  errorModalAdditionalText?: string;
  onCertidaoButtonClick?: () => void;
  onCadernetaButtonClick?: () => void;
  onCertidaoRegistoComercialButtonClick?: () => void;
  onClose: () => void;
}

export const ModalDocumentsValidationError: React.FC<Props> = ({
  jobId,
  errorType,
  errorModalAdditionalText,
  onCertidaoButtonClick,
  onCadernetaButtonClick,
  onCertidaoRegistoComercialButtonClick,
  onClose,
}) => {
  const textColor = useColorModeValue('navy.750', 'white');
  const contactSupportTextColor = useColorModeValue('navy.900', 'white');

  const { t } = useTranslation();

  const { isOpen } = useDisclosure({ isOpen: !!errorType, onClose });
  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));

  const ErrorTitleByErrorType: Record<DocumentsValidationErrorTypeEnum, string> = {
    [DocumentsValidationErrorTypeEnum.CadernetaAccessCodeInvalid]: t('pages.details.validationErrorModal.title'),
    [DocumentsValidationErrorTypeEnum.CertidaoAccessCodeInvalid]: t('pages.details.validationErrorModal.title'),
    [DocumentsValidationErrorTypeEnum.FatalError]: t('pages.details.validationErrorModal.title'),
    [DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing]: t(
      'pages.details.validationErrorModal.requiredFileMissingTitle',
    ),
    [DocumentsValidationErrorTypeEnum.TooManyDocuments]: t('pages.details.validationErrorModal.tooManyDocuments'),
    [DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable]: t(
      'pages.details.validationErrorModal.certidaoIsUnaceptable',
    ),
    [DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid]: t(
      'pages.details.validationErrorModal.title',
    ),
    [DocumentsValidationErrorTypeEnum.CertidaoPropertyIdMismatch]: t(
      'pages.details.validationErrorModal.propertyIdMismatch',
    ),
    [DocumentsValidationErrorTypeEnum.NO_DATA_FOUND_ON_DOCUMENT]: t(
      'pages.details.validationErrorModal.noDataFoundOnDocument',
    ),
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent textColor={textColor} p="24px" borderRadius="16px" display="flex">
        <ModalHeader p="0" fontSize="24px">
          {t('error')}
        </ModalHeader>
        <ModalCloseButton right="24px" top="none" />
        <ModalBody p="24px 0">
          <Flex alignItems="center" direction="column" gap="24px">
            <ErrorInSync w="32px" h="32px" />
            <Text fontWeight="bold" fontSize="20px" textAlign="center">
              {ErrorTitleByErrorType[errorType as DocumentsValidationErrorTypeEnum]}
            </Text>
            {errorModalAdditionalText && (
              <Text fontWeight={'bold'} fontSize="16px" textAlign="center">
                {t(errorModalAdditionalText)}
              </Text>
            )}
            {errorType !== DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable &&
              errorType !== DocumentsValidationErrorTypeEnum.TooManyDocuments &&
              errorType !== DocumentsValidationErrorTypeEnum.NO_DATA_FOUND_ON_DOCUMENT && (
                <Text fontSize="16px" textAlign="center">
                  <Trans i18nKey="pages.details.validationErrorModal.subtitle" />
                </Text>
              )}
            {errorType === DocumentsValidationErrorTypeEnum.TooManyDocuments && (
              <Text fontSize="16px" textAlign="center">
                {t('pages.details.validationErrorModal.tooManyDocumentsSubtitle')}
              </Text>
            )}
            {errorType === DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable && (
              <>
                <Text fontSize="16px" textAlign="center">
                  {t('pages.details.validationErrorModal.oneCertidaoAllowed', { code: job?.certidaoIdentifier })}
                </Text>
                <Text fontSize="16px" textAlign="center">
                  {t('pages.details.validationErrorModal.certidaoUnacceptable', { code: job?.certidaoIdentifier })}
                </Text>
              </>
            )}

            <Text fontSize="14px" textAlign="center">
              <Trans
                i18nKey="pages.details.validationErrorModal.contactSupport"
                components={{
                  emailLink: (
                    <Link
                      fontWeight={'bold'}
                      textDecoration={'underline'}
                      href={`mailto:${environment.contactEmail}`}
                      textColor={contactSupportTextColor}
                    ></Link>
                  ),
                }}
              ></Trans>
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0" flexWrap="wrap">
          {errorType === DocumentsValidationErrorTypeEnum.CadernetaAccessCodeInvalid && (
            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={() => {
                onClose();
                onCadernetaButtonClick?.();
              }}
              title={t('pages.details.addCadernetaWithAccessCode')}
            />
          )}

          {errorType === DocumentsValidationErrorTypeEnum.CertidaoAccessCodeInvalid && (
            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={() => {
                onClose();
                onCertidaoButtonClick?.();
              }}
              title={t('pages.details.addCertidaoPermanenteWithAccessCode')}
            />
          )}

          {errorType === DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid && (
            <AppButton
              buttonType={AppButtonType.MAIN}
              onClick={() => {
                onClose();
                onCertidaoRegistoComercialButtonClick?.();
              }}
              title={t('pages.details.addCertidaoRermananteRegistoComercialWithAccessCode')}
            />
          )}

          {(errorType === DocumentsValidationErrorTypeEnum.FatalError ||
            errorType === DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing ||
            DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable) && (
            <AppButton buttonType={AppButtonType.MAIN} onClick={onClose} title="Ok" />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
