import { api } from './api';
import { EntityType } from './submission';

export enum UniversalDocumentStatus {
  CREATED = 'CREATED',
  UPLOADED = 'UPLOADED',
}

export interface UniversalDocumentMetadataBase {
  orderIndex?: number;
}

export type UniversalDocumentMetadata = UniversalDocumentMetadataBase | UniversalDocumentSigningMetadata;

export enum SigningDocumentType {
  INPUT = 'INPUT',
  PREPARED = 'PREPARED',
  SIGNED = 'SIGNED',
  DECLARATION = 'DECLARATION',
}

export type UniversalDocumentSigningMetadata =
  | UniversalDocumentSigningInputMetadata
  | UniversalDocumentSigningPreparedMetadata
  | UniversalDocumentSigningSignedMetadata
  | UniversalDocumentSigningDeclarationMetadata;

export interface UniversalDocumentSigningInputMetadata extends UniversalDocumentMetadataBase {
  signingDocumentType: SigningDocumentType.INPUT;
}

export interface UniversalDocumentSigningPreparedMetadata extends UniversalDocumentMetadataBase {
  signingDocumentType: SigningDocumentType.PREPARED;
  anchors: Record<string, number[]>;
}

export interface UniversalDocumentSigningSignedMetadata extends UniversalDocumentMetadataBase {
  signingDocumentType: SigningDocumentType.SIGNED;
}

export interface UniversalDocumentSigningDeclarationMetadata extends UniversalDocumentMetadataBase {
  signingDocumentType: SigningDocumentType.DECLARATION;
}

export interface UniversalDocument<T extends UniversalDocumentMetadataBase = UniversalDocumentMetadataBase> {
  id: string;

  fileId?: string;
  name: string;
  metadata?: T;
  entityType: EntityType;
  entityId: string;
  status: UniversalDocumentStatus;
  url?: string;

  createdBy: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface UniversalDocumentCreateDto {
  name: string;
  metadata?: unknown;
  entityType: EntityType;
  entityId: string;
}

export interface UniversalDocumentUploadDto {
  id: string;
  formData?: FormData;
}

const universalDocumentApi = api.injectEndpoints({
  endpoints: build => ({
    create: build.mutation<UniversalDocument, UniversalDocumentCreateDto>({
      query: data => ({ url: `/universal-document`, method: 'POST', body: data }),
    }),
    upload: build.mutation<UniversalDocument, UniversalDocumentUploadDto>({
      query: ({ id, formData }) => ({ url: `/universal-document/${id}/upload`, body: formData, method: 'POST' }),
    }),
    delete: build.mutation<UniversalDocument, { id: string }>({
      query: ({ id }) => ({ url: `/universal-document/${id}`, method: 'DELETE' }),
    }),
    reorder: build.mutation<UniversalDocument[], { orderedIds: string[] }>({
      query: ({ orderedIds }) => ({ url: `/universal-document/reorder`, method: 'POST', body: { orderedIds } }),
    }),
  }),
});

export const {
  useCreateMutation: useCreateUniversalDocumentMutation,
  useUploadMutation: useUploadUniversalDocumentMutation,
  useDeleteMutation: useDeleteUniversalDocumentMutation,
  useReorderMutation: useReorderUniversalDocumentMutation,
  endpoints: UniversalDocumentEndpoints,
} = universalDocumentApi;
