import { useState, useEffect } from 'react';
import {
  Box,
  useColorModeValue,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Flex,
  InputRightElement,
  MenuButton,
  Menu,
  Button,
  MenuList,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import { Job, useLazyGetJobsListPrivateQuery } from '../api';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Cancel, Magnifier } from '../libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface JobSelectorProps {
  selectedJob?: Job;
  onJobSelect: (job: Job) => void;
}

export const JobSelector = (props: JobSelectorProps) => {
  const { selectedJob, onJobSelect } = props;

  const { t } = useTranslation();

  const [fetch, { isLoading }] = useLazyGetJobsListPrivateQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const [jobs, setJobs] = useState<Job[]>([]);

  const fetchOptions = async () => {
    if (isLoading || !hasMore) return;

    const result = await fetch({ skip: jobs.length || 0, take: 15, search });
    if (result.data?.data) {
      setJobs([...jobs, ...result.data.data]);
      if (result.data?.data?.length < 15) {
        setHasMore(false);
      }
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [search]);

  const itemBgColor = useColorModeValue('_gray.100', 'whiteAlpha.400');
  const textColor = useColorModeValue('brand.800', 'white');
  const shadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.32)',
    '14px 17px 40px 8px rgba(112, 144, 176, 0.06)',
  );

  return (
    <FormControl w={'100%'}>
      <FormLabel mb={'4px'}>{t('jobSelector.selectJob')}</FormLabel>
      <Menu matchWidth isOpen={isOpen}>
        <MenuButton
          width={'100%'}
          borderColor={itemBgColor}
          border="1px solid"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          onClick={() => setIsOpen(!isOpen)}
          h="50px"
          borderRadius={'10px'}
          fontWeight={selectedJob ? 600 : 400}
        >
          {selectedJob?.name || t('jobSelector.selectJob')}
        </MenuButton>

        <MenuList p="0" boxShadow={shadow} border="none" overflow={'hidden'} padding="0">
          <Flex flexDirection={'column'} gap="4px" width={'100%'}>
            <Box p="8px 8px 0 8px">
              <InputGroup borderRadius={'10px'}>
                <InputLeftElement pointerEvents="none">
                  <Magnifier color={textColor} />
                </InputLeftElement>
                <Input
                  w={'100%'}
                  borderRadius={'10px'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  placeholder={t('search')}
                  color={textColor}
                  value={search}
                  onChange={e => {
                    setHasMore(true);
                    setJobs([]);
                    setSearch(e.target.value);
                  }}
                />
                {search && (
                  <InputRightElement cursor={'pointer'} onClick={() => setSearch('')}>
                    <Cancel color={textColor} w="12px" h="12px" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>

            <Box id="scrollableDiv" maxH={'300px'} overflow="auto" p={'8px'}>
              <InfiniteScroll
                dataLength={jobs.length}
                next={fetchOptions}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {jobs.map((job: Job) => (
                  <Box
                    height={'40px'}
                    w={'100%'}
                    key={job.id}
                    p="8px 16px"
                    cursor={'pointer'}
                    borderRadius="10px"
                    _hover={{ bg: itemBgColor }}
                    onClick={() => {
                      onJobSelect(job);
                      setIsOpen(false);
                    }}
                  >
                    <Text fontWeight={500} fontSize="16px">
                      {job.name}
                    </Text>
                  </Box>
                ))}
              </InfiniteScroll>
            </Box>
          </Flex>
        </MenuList>
      </Menu>
    </FormControl>
  );
};
