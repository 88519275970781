import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { ThreeDotsVertical } from '../../libs/ui/atoms/src';

export interface SortableItemProps {
  id: string;
  orderIndex: number;
  title: string;
  iconChildren: React.ReactNode;
  rightAction?: React.ReactNode;
}

export const SortableListItem = ({ id, orderIndex, title, iconChildren, rightAction }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const bgColor = useColorModeValue('white', 'navy.700');
  const bgColorHighlight = useColorModeValue('blue.50', 'navy.750');

  const colorIndex = useColorModeValue('_gray.700', 'white');
  const colorIndexActive = useColorModeValue('white', 'white');
  const bgColorIndex = useColorModeValue('_gray.200', 'whiteAlpha.400');
  const bgColorIndexActive = useColorModeValue('_brand.550', '_brand.550');

  return (
    <Box
      borderRadius="16px"
      overflow={'hidden'}
      padding={'16px 24px 16px 0'}
      bg={bgColor}
      _active={{ bg: bgColorHighlight }}
      cursor={'grab'}
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      {...attributes}
      // {...listeners}
      onPointerDown={e => {
        for (const key in listeners) {
          listeners[key](e);
        }
      }}
    >
      <Flex alignItems={'center'} justifyContent="space-between" gap="16px" overflow={'hidden'}>
        <Flex alignItems={'center'} gap="16px" overflow={'hidden'}>
          <ThreeDotsVertical color={isDragging ? bgColorIndexActive : bgColorIndex} />
          <Box
            minH="24px"
            minW="24px"
            borderRadius="50%"
            bg={isDragging ? bgColorIndexActive : bgColorIndex}
            color={isDragging ? colorIndexActive : colorIndex}
            textAlign="center"
            fontSize={'16px'}
            fontWeight={'bold'}
          >
            {orderIndex}
          </Box>

          {iconChildren}

          <Box className="no-text-wrap" fontSize={'16px'} fontWeight="500" textOverflow={'ellipsis'} overflow="hidden">
            {title}
          </Box>
        </Flex>

        {rightAction}
      </Flex>
    </Box>
  );
};
