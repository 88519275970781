import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import { useColorMode } from '@chakra-ui/system';
import { Box } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';

export interface VCDatePickerProps {
  isNotInModal?: boolean;
  isDisabled?: boolean;
  placeholder: string;
  selectedValue?: Date;
  onChange: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  filterDate?: (date: Date) => boolean;
  portalId?: string;
  onBlur?: () => void;
}

/**
 * VCDatePicker component renders a styled Datepicker using Chakra UI, with support for light and dark modes.
 * @param {Object} props - Component props.
 * @param {string} props.placeholder - Placeholder text for the Datepicker input field.
 * @param {Date | null} props.selectedValue - Currently selected date value.
 * @param {function} props.onChange - Callback function triggered on date selection change.
 * @param {function} props.filterDate - Function to filter selectable dates.
 * @param {string} props.portalId - ID of the portal element where the Datepicker calendar is appended. It's needed for correct
 * scrolling and positioning. Also note that portalId should start with 'chakra-modal-' prefix to work properly
 * @returns {JSX.Element} Rendered VCDatePicker component.
 */
export const VCDatePicker = ({
  isNotInModal,
  isDisabled,
  placeholder,
  selectedValue,
  onChange,
  filterDate,
  portalId,
  onBlur,
}: VCDatePickerProps) => {
  const { colorMode } = useColorMode();

  const minDate = new Date();

  return (
    <Box
      height={'50px'}
      borderWidth="1px"
      borderRadius="6px"
      width="100%"
      borderColor={colorMode === 'light' ? globalStyles.colors.gray['200'] : 'rgba(255, 255, 255, 0.16)'}
      _hover={{
        borderColor: colorMode === 'light' ? globalStyles.colors.gray['300'] : 'rgba(255, 255, 255, 0.24)',
      }}
      _focusWithin={{
        borderColor: colorMode === 'light' ? globalStyles.colors.blue['500'] : '#63b3ed',
        boxShadow: colorMode === 'light' ? '0 0 0 1px #3965FF' : '0 0 0 1px #63b3ed',
      }}
      transition={'all 0.2s'}
    >
      <DatePicker
        readOnly={isDisabled}
        placeholderText={placeholder}
        onChange={onChange}
        selected={selectedValue}
        filterDate={filterDate}
        portalId={portalId}
        className={`vc-react-datepicker-input ${colorMode === 'light' ? 'vc-datepicker-light' : 'vc-datepicker-dark'} ${
          isNotInModal ? 'vc-datepicker-not-in-modal' : ''
        }`}
        calendarClassName={`vc-react-datepicker-calendar ${
          colorMode === 'light' ? 'vc-datepicker-light' : 'vc-datepicker-dark'
        }`}
        wrapperClassName="vc-react-datepicker-wrapper"
        calendarStartDay={1}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={minDate}
        onBlur={onBlur}
      />
    </Box>
  );
};
