import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Signer, signerApi, SignerEndpoints } from '../api/signer';
import { RootState } from './index';

export interface SignerState {
  signers: Signer[];
}

const initialState: SignerState = {
  signers: [] as Signer[],
};

const signersSlice = createSlice({
  name: 'signers',
  initialState,
  reducers: {
    setSigners: (state, action: PayloadAction<Signer[]>) => {
      state.signers = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(SignerEndpoints.getSigners.matchFulfilled, (state, action: PayloadAction<Signer[]>) => {
      state.signers = action.payload;
    });
  },
});

export const { setSigners } = signersSlice.actions;

export const selectSigners = (state: RootState) => state.signers.signers;

export const { reducer: signersReducer, reducerPath: signersReducerPath } = signersSlice;

export const { endpoints: signingEndpoints } = signerApi;
