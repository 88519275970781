import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Text,
  useColorModeValue,
  Flex,
  ModalFooter,
  Box,
} from '@chakra-ui/react';
import { Information } from 'libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

interface ProblemsNavigationModalProps {
  jobId: string;
  isOpen: boolean;
  onClose: () => void;
  onResolveProblems: () => void;
}

export const ProblemsNavigationModal = ({
  jobId,
  isOpen,
  onResolveProblems,
  onClose,
}: ProblemsNavigationModalProps) => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <Modal size={'md'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent color={textColor} borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalHeader>{t('actionRequired')}</ModalHeader>
        <ModalCloseButton top={'6'} right="4" />
        <ModalBody>
          <Flex flexDirection={'column'} gap={'16px'} alignItems={'center'}>
            <Information w="64px" h="64px" color={'gray.300'} />

            <Text
              dangerouslySetInnerHTML={{
                __html: t(
                  'pages.details.thereAreSomeProblemsWithYourFilesYouCanProceedToResultsOrViewAndResolveProblems',
                ) as string,
              }}
              fontSize="14px"
              textAlign="center"
              pb={'16px'}
            ></Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          <AppButton
            buttonType={AppButtonType.MAIN}
            onClick={onResolveProblems}
            title={t('pages.details.viewAndResolveProblems')}
          />

          <Box>
            <Link to={`/job-result/${jobId}`}>
              <AppButton
                buttonType={AppButtonType.MAIN}
                onClick={onResolveProblems}
                title={t('pages.details.goToResults')}
              />
            </Link>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
