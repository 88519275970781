export const isEqual = <T extends Record<string, unknown>>(obj1: Partial<T>, obj2: Partial<T>): boolean => {
  return Object.entries(obj1).every(([key, value]) => obj2[key as keyof T] === value);
};

export const isArraysEqual = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) return false;
  if (arr1.length) return true;

  const arr1Sorted = arr1.sort();
  const arr2Sorted = arr2.sort();
  return arr1Sorted.every((value, index) => value === arr2Sorted[index]);
};
