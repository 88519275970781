import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DocumentStatus,
  tByDocumentSubmissionErrorMessage,
  JobDocument,
  JobDocumentWithJobId,
  SubmissionDocumentImportByCpcStatus,
  useLazyGetAllJobDocumentsQuery,
  useLazyGetJobDocumentsQuery,
  useDeleteJobDocumentMutation,
  DOCUMENT_SUBMISSION_STATUS_DELETION_TRIGGER_ERRORS,
} from '../../../../api';
import { isArraysEqual } from '../../../../utils/is-equal';
import { useTranslation } from 'react-i18next';

export const useDocumentsPolling = ({
  jobId,
  onNewDocumentsFetched,
  onError,
}: {
  jobId: string;
  onNewDocumentsFetched: (documents: JobDocumentWithJobId[]) => void;
  onError?: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const [getJobDocumentsByIds] = useLazyGetJobDocumentsQuery();
  const [getJobDocuments] = useLazyGetAllJobDocumentsQuery();
  const [deleteJobDocument] = useDeleteJobDocumentMutation();

  const [documentIdsToPoll, setDocumentIdsToPoll] = useState<string[]>([]);
  const documentIdsToPollRef = useRef(documentIdsToPoll);

  useEffect(() => {
    documentIdsToPollRef.current = documentIdsToPoll;
  }, [documentIdsToPoll]);

  const addDocumentsToPolling = useCallback((documents: JobDocument[]) => {
    const ids = documentIdsToPollRef.current;
    setDocumentIdsToPoll([...new Set([...ids, ...documents.map(d => d.id)])]);
  }, []);

  const getAllDocuments = useCallback(async () => {
    try {
      const response = await getJobDocuments({ jobId });
      const jobDocuments = response.data ?? [];
      const newPollingDocuments = jobDocuments.filter(d => d.status === DocumentStatus.CREATED && !d.type);
      addDocumentsToPolling(newPollingDocuments);
    } catch (error) {
      console.error('Failed to get all documents', error);
    }
  }, [addDocumentsToPolling, getJobDocuments, jobId]);

  const handleDocumentError = useCallback(
    async (documentId: string, errorStatus: SubmissionDocumentImportByCpcStatus) => {
      const translationKey = tByDocumentSubmissionErrorMessage[errorStatus];
      const errorMessage = translationKey ? t(translationKey) : 'Unknown error';

      onError?.(errorMessage);

      await deleteJobDocument({ id: documentId })
        .unwrap()
        .catch(deleteError => {
          console.error(`Failed to delete document ${documentId}:`, deleteError);
        });
    },
    [deleteJobDocument, t, onError],
  );

  const pollDocumentsByIds = useCallback(async () => {
    const ids = documentIdsToPollRef.current;
    if (!ids.length) {
      return;
    }

    try {
      const documents = await getJobDocumentsByIds({ ids }).unwrap();
      onNewDocumentsFetched(documents);

      const removeFromPollingDocumentIds = documents
        .filter(d => d.status !== DocumentStatus.CREATED || d.type)
        .map(d => d.id);
      const newPollingDocumentIds = ids.filter(
        id => documents.some(d => d.id === id) && !removeFromPollingDocumentIds.includes(id),
      );

      if (!isArraysEqual(ids, newPollingDocumentIds)) {
        setDocumentIdsToPoll(newPollingDocumentIds);
      }

      const deletedPollingDocuments = ids.filter(id => !documents.some(doc => doc.id === id));
      if (deletedPollingDocuments.length) {
        getAllDocuments();
      }

      for (const document of documents) {
        const submission = document.submission;
        if (submission && DOCUMENT_SUBMISSION_STATUS_DELETION_TRIGGER_ERRORS.includes(submission.status)) {
          handleDocumentError(document.id, submission.status);
        }
      }
    } catch (error) {
      onError?.((error as Error).message);
    }
  }, [getAllDocuments, getJobDocumentsByIds, onNewDocumentsFetched, handleDocumentError, onError]);

  useEffect(() => {
    const interval = setInterval(() => pollDocumentsByIds(), 1000);
    return () => clearInterval(interval);
  }, [pollDocumentsByIds]);

  return { addDocumentsToPolling };
};
