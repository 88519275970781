import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, Grid, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function SidebarLinks(props: { routes: RoutesType[]; isCollapsed: boolean }) {
  //   Chakra color mode
  const location = useLocation();
  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  const activeIcon = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const { routes, isCollapsed } = props;

  const { t } = useTranslation();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  //  BRAND
  return (
    <>
      {routes.map((route, index) => (
        <NavLink key={index} to={route.layout === '/' ? route.path : route.layout + route.path}>
          {route.icon ? (
            <Box
              padding={route.customAction && isCollapsed ? '4px 0 4px 0' : 0}
              backgroundColor={activeRoute(route.path.toLowerCase()) ? boxBg : ''}
              borderRadius="8px 0 0 8px"
            >
              <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}>
                <Flex
                  w="100%"
                  flexDirection={isCollapsed ? 'column' : 'row'}
                  gap={isCollapsed ? '24px' : '0'}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box color={activeRoute(route.path.toLowerCase()) ? brandColor : textColor} mx="8px">
                    {route.icon}
                  </Box>
                  {!isCollapsed && (
                    <Text
                      me="auto"
                      color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                      fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                    >
                      {t(route.translatedName)}
                    </Text>
                  )}
                  {route.customAction}
                </Flex>
                <Box
                  h="36px"
                  w="4px"
                  bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
                  borderRadius="5px"
                />
              </HStack>
            </Box>
          ) : (
            <Box>
              <HStack spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'} py="5px" ps="10px">
                <Text
                  me="auto"
                  color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                  fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                >
                  {route.name}
                </Text>
                <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
              </HStack>
            </Box>
          )}
        </NavLink>
      ))}
    </>
  );
}

export default SidebarLinks;
