import { useEffect } from 'react';
import { environment } from './environments/environment';

export const PageInitializators = () => {
  const appendScriptToHeader = async () => {
    try {
      const text = await fetch('/assets/new-relic.dev-browser-agent-script.txt').then(response => response.text());

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = text;
      document.head.appendChild(script);
    } catch (error) {
      console.error('Failed to append New Relic script to header', error);
    }
  };

  useEffect(() => {
    let title = 'VeriCasa';

    if (environment.stage === 'local' || environment.stage === 'dev') {
      title = 'VeriCasa - DEV';
    } else if (environment.stage === 'prod') {
      title = 'VeriCasa';
    }

    document.title = title;

    if (environment.stage === 'dev') {
      appendScriptToHeader();
    }
  }, []);

  return null;
};
