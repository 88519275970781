import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ApproveTick, ExclamationMark } from 'libs/ui/atoms/src';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, AppButtonType } from '../../../../libs/ui/atoms/src/lib/appButton';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSuccess?: boolean | null;
}
export function ChangePasswordModal({ isOpen, onClose, isSuccess }: ChangePasswordModalProps) {
  const { t } = useTranslation();
  const { isOpen: isOpenDisclosure, onOpen: onOpenDisclosure, onClose: onCloseDisclosure } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      onOpenDisclosure();
    } else {
      onCloseDisclosure();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpenDisclosure} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('changePassword')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="center">
            {isSuccess ? (
              <>
                <ApproveTick color="green.200" boxSize={20} />
                <Text fontWeight="500" textAlign="center">
                  {t('pages.profile.changePasswordNotification_success')}
                </Text>
              </>
            ) : (
              <>
                <ExclamationMark color="red.500" boxSize={20} />
                <Text fontWeight="500" textAlign="center">
                  {t('pages.profile.changePasswordNotification_fail')}
                </Text>
              </>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <AppButton
            buttonType={AppButtonType.PRIMARY}
            colorScheme="brand"
            color="white"
            onClick={onClose}
            title={t('close')}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
