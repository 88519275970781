import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndContext, useSensor, useSensors, PointerSensor, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableListItem } from './SortableListItem';
import { areArraysEqual } from '../../utils/are-arrays-equals';
import { Flex } from '@chakra-ui/react';

export interface SortableListItem {
  id: string;
  orderIndex: number;
  title: string;
  iconChildren: React.ReactNode;
  rightAction?: React.ReactNode;
}

export interface SortableListProps {
  isDisabled?: boolean;
  items: SortableListItem[];
  onMove: (ids: string[]) => Promise<string[]>;
}

export const SortableList = ({ items: propItems, onMove, isDisabled }: SortableListProps) => {
  const [items, setItems] = useState<SortableListItem[]>([]);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    if (!isDraggingRef.current) {
      setItems(prevItems => {
        const prevItemIndexMap = new Map(items.map((item, index) => [item.id, index]));

        return [...propItems].sort((a, b) => {
          const indexA = prevItemIndexMap.has(a.id) ? prevItemIndexMap.get(a.id)! : Number.MAX_SAFE_INTEGER;
          const indexB = prevItemIndexMap.has(b.id) ? prevItemIndexMap.get(b.id)! : Number.MAX_SAFE_INTEGER;

          return indexA - indexB;
        });
      });
    }
  }, [propItems]);

  const sensors = useSensors(useSensor(PointerSensor));
  const handleDragStart = useCallback(() => {
    isDraggingRef.current = true;
  }, []);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const prevOrdered = [...items];
      const prevOrderedIds = items.map(item => item.id);
      const { active, over } = event;

      if (!over || active.id === over.id) {
        isDraggingRef.current = false;
        return;
      }

      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex).map((item, idx) => ({ ...item, orderIndex: idx }));

      setItems(newItems);
      isDraggingRef.current = false;

      const newOrderedIds = await onMove(newItems.map(item => item.id));
      if (!areArraysEqual(prevOrderedIds, newOrderedIds)) {
        setItems(prevOrdered);
      }
    },
    [items, onMove, setItems],
  );

  return (
    <Flex flexDir={'column'} gap="2px" p={`${!items.length ? 0 : 24}px 24px 24px 24px`}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          disabled={isDisabled}
          items={items.map(item => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {items.map(({ orderIndex, ...item }, idx) => (
            <SortableListItem key={item.id} orderIndex={idx + 1} {...item} />
          ))}
        </SortableContext>
      </DndContext>
    </Flex>
  );
};

export default SortableList;
