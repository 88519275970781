import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Plus } from 'libs/ui/atoms/src';

import { initUserFormData, useUserForm } from './use-user-form';
import { UserMutateForm } from './MutateUserForm';
import { useInviteUserMutation, UserInviteDto } from '../../../../../../api';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

export interface InviteUserButtonProps {
  onInvited?: () => void;
  onModalClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InviteUserButton = ({ onInvited, onModalClose }: InviteUserButtonProps) => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { form, dispatch, validate } = useUserForm();
  const [isLoading, setIsLoading] = useState(false);

  const [inviteUser] = useInviteUserMutation();

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: initUserFormData });

    if (onModalClose) {
      onModalClose(false);
    }
    onClose();
  }, [dispatch, onModalClose, onClose]);

  const onSubmitClick = useCallback(async () => {
    const validationMessages = validate();
    if (validationMessages.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);
      const result = await inviteUser({
        ...(form as UserInviteDto),
        locale: i18n.language.toUpperCase(),
      });

      if (!result.data) {
        toast({
          title: t('relatedParties.unableToCreateRelatedParty'),
          description: ((result.error as FetchBaseQueryError).data as Error)?.message,
          status: 'error',
          duration: 5000,
        });
        return;
      }
      onInvited?.();
      close();
    } catch (error) {
      toast({
        title: t('relatedParties.unableToCreateRelatedParty'),
        description: ((error as FetchBaseQueryError).data as Error)?.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [validate, toast, t, inviteUser, form, i18n.language, onInvited, close]);

  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <>
      <AppButton
        buttonType={AppButtonType.PRIMARY}
        leftIcon={<Plus w="12px" h="12px" />}
        onClick={onOpen}
        title={t('invite')}
      />

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('inviteUser')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody onClick={e => e.stopPropagation()} p="24px 1px 24px 1px">
            <UserMutateForm form={form} dispatch={dispatch} />
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              isLoading={isLoading}
              onClick={onSubmitClick}
              title={t('invite')}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
