import { Trans, useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { initCreateRelatedPartyData, useRelatedPartyForm } from './useRelatedPartyForm';
import {
  RelatedParty,
  RelatedPartyCreateDto,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  useCreateRelatedPartyMutation,
  useRelatedPartySubmitByCodeMutation,
} from 'api/related-parties';
import { MutateRelatedPartyForm } from './MutateRelatedPartyForm';
import { PersonAdd } from 'libs/ui/atoms/src';
import {
  CertidaoRegistoComercialRelationType,
  DocumentStatus,
  JobDocumentWithJobId,
  useAddCertidaoRegistoComercialWithAccessCodeMutation,
} from '../../../../../../api';
import {
  DocumentsValidationErrorTypeEnum,
  ModalDocumentsValidationError,
} from '../../JobDocumentsWidget/DocumentValidationModals';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../../../libs/ui/atoms/src/lib/appButton';
import { useDocumentsPolling } from '../../../hooks/use-documents-polling';
import { useAppToast } from '../../../../../../libs/ui/hooks';

export enum CreateRelatedPartyButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

const relationByEntityType: Record<RelatedPartyEntityType, CertidaoRegistoComercialRelationType> = {
  [RelatedPartyEntityType.BUYER]: CertidaoRegistoComercialRelationType.BUYER,
  [RelatedPartyEntityType.SELLER]: CertidaoRegistoComercialRelationType.SELLER,
};

export interface CreateRelatedPartyButtonProps {
  type?: CreateRelatedPartyButtonType;
  jobId: string;
  entityType: RelatedPartyEntityType;
  parentRelatedParty?: RelatedParty;

  relatedPartyId?: string;
  relationType?: RelatedPartyRelationType;

  onSubmit: (data: RelatedPartyCreateDto) => Promise<void>;
  onCodeSubmit?: () => void;

  isModalOpen?: boolean; // External prop for modal open/close
  onModalClose?: React.Dispatch<React.SetStateAction<boolean>>;

  onOpenDropzone?: (relatedParty?: RelatedParty) => void;
}

export const CreateRelatedPartyButton = ({
  type = CreateRelatedPartyButtonType.PRIMARY,
  jobId,
  entityType,
  relatedPartyId,
  relationType,
  parentRelatedParty,
  onSubmit,
  onCodeSubmit,

  isModalOpen,
  onModalClose,
  onOpenDropzone,
}: CreateRelatedPartyButtonProps) => {
  const { t } = useTranslation();
  const { showError, showApiError } = useAppToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isShowButtonText = useBreakpointValue({ base: true, sm: false, md: true, lg: true, xl: true });

  const { form, dispatch, validate } = useRelatedPartyForm();
  const [isLoading, setIsLoading] = useState(false);

  const [certidaoAccessCode, setCertidaoAccessCode] = useState<string>('');
  const [errorModalAdditionalText, setErrorModalAdditionalText] = useState<string | undefined>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [submitByCode, { isLoading: isLoadingSubmitByCode }] = useRelatedPartySubmitByCodeMutation();
  const [addCertidaoRegistoComercialWithAccessCode, { isLoading: isLoadingCertidaoRegistoComercialWithAccessCode }] =
    useAddCertidaoRegistoComercialWithAccessCodeMutation({});
  const [createRelatedParty] = useCreateRelatedPartyMutation();

  const [processingDocumentId, setProcessingDocumentId] = useState<string | null>(null);
  const processingPromiseRef = useRef<{ resolve: () => void; reject: (error: Error) => void } | null>(null);

  const onNewDocumentsFetched = useCallback(
    (documents: JobDocumentWithJobId[]) => {
      const doc = documents.find((d: JobDocumentWithJobId) => d.id === processingDocumentId);
      if (doc && doc.status === DocumentStatus.INITIALIZED && processingPromiseRef.current) {
        processingPromiseRef.current.resolve();
        processingPromiseRef.current = null;
      }
    },
    [processingDocumentId],
  );

  const handlePollingError = useCallback((message: string) => {
    setErrorModalAdditionalText(message);
    setIsShowErrorModal(true);
    setIsLoading(false);
  }, []);

  const { addDocumentsToPolling } = useDocumentsPolling({
    jobId,
    onNewDocumentsFetched,
    onError: handlePollingError,
  });

  const close = useCallback(() => {
    dispatch({
      type: 'setInitialState',
      value: { ...initCreateRelatedPartyData, relatedPartyId, ...(relationType ? { relationType } : {}) },
    });
    if (onModalClose) {
      onModalClose(false);
    }
    onClose();
    setTabIndex(0);
    setCertidaoAccessCode('');
  }, [dispatch, relatedPartyId, relationType, onModalClose, onClose, setCertidaoAccessCode]);

  useEffect(() => {
    dispatch({
      type: 'setInitialState',
      value: { ...initCreateRelatedPartyData, relatedPartyId, ...(relationType ? { relationType } : {}) },
    });
  }, [dispatch, relatedPartyId, relationType]);

  const submitCertidaoCode = useCallback(
    async (code: string) => {
      try {
        setErrorModalAdditionalText(undefined);
        const addedDocuments = await addCertidaoRegistoComercialWithAccessCode({
          jobId,
          dto: { code, relationType: relationByEntityType[entityType] },
        }).unwrap();
        return addedDocuments[0];
      } catch (e: any) {
        console.error('Failed to add Certidao Registo Comercial with access code', code, e);
        if (
          e.data?.message?.includes('format is incorrect') ||
          e.data?.message?.includes('Não existe qualquer certidão activa com esse número.') ||
          e.data?.message?.includes('Não existe qualquer certidão com esse número.')
        ) {
          setErrorModalAdditionalText(e.data?.message);
        }

        setIsShowErrorModal(true);
      }
    },
    [addCertidaoRegistoComercialWithAccessCode, entityType, jobId],
  );

  const onSubmitByCode = useCallback(async () => {
    setIsLoading(true);
    try {
      const document = await submitCertidaoCode(certidaoAccessCode);
      if (!document) return;

      setProcessingDocumentId(document.id);
      addDocumentsToPolling([document]);

      await new Promise<void>((resolve, reject) => {
        processingPromiseRef.current = { resolve, reject };
      });

      const newParty = await createRelatedParty({
        entityType,
        jobId,
        relationType: RelatedPartyRelationType.COMPANY,
        relatedPartyId,
      }).unwrap();

      await submitByCode({ id: newParty.id, documentId: document.id, code: certidaoAccessCode });
      onCodeSubmit?.();
      close();
    } catch (error) {
      showApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    certidaoAccessCode,
    close,
    createRelatedParty,
    entityType,
    jobId,
    onCodeSubmit,
    relatedPartyId,
    submitByCode,
    submitCertidaoCode,
    addDocumentsToPolling,
    showApiError,
  ]);

  const onSubmitClick = useCallback(async () => {
    if (tabIndex === 0 && form.relationType === RelatedPartyRelationType.COMPANY) {
      if (isLoadingCertidaoRegistoComercialWithAccessCode) return;
      try {
        setIsLoading(true);
        await onSubmitByCode();
      } finally {
        setIsLoading(false);
      }
      return;
    }

    const validationMessages = validate();
    if (validationMessages.length > 0) {
      showError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit({
        ...(form as RelatedPartyCreateDto),
        phone: form?.phone ? '+' + form.phone : undefined,
        jobId,
        entityType,
      });

      close();
    } catch (error) {
      showApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    tabIndex,
    validate,
    isLoadingCertidaoRegistoComercialWithAccessCode,
    onSubmitByCode,
    onSubmit,
    form,
    jobId,
    entityType,
    close,
    showApiError,
    showError,
    t,
  ]);

  const textColor = useColorModeValue('navy.750', 'white');
  const actionTextColor = useColorModeValue('navy.900', 'white');

  return (
    <>
      {type === CreateRelatedPartyButtonType.SECONDARY ? (
        <AppButton
          buttonType={AppButtonType.XSMALL}
          size={'xs'}
          leftIcon={<PersonAdd w="14px" h="14px" />}
          onClick={e => {
            e.stopPropagation();
            onOpen();
          }}
          title={t('add')}
          colorSchemes={[AppButtonColorScheme.SUCCESS]}
        />
      ) : (
        <></>
      )}

      <Modal
        size="xl"
        isOpen={
          type === CreateRelatedPartyButtonType.SECONDARY ? isOpen : isModalOpen !== undefined ? isModalOpen : false
        }
        onClose={close}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t(entityType === RelatedPartyEntityType.BUYER ? 'relatedParties.addBuyer' : 'relatedParties.addSeller')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody onClick={e => e.stopPropagation()} p=" 0 1px 24px 1px">
            <Text mb={'16px'} fontSize="14px">
              <Trans
                i18nKey="relatedParties.clickHereToImportDataFromIDsInsteadOfFillingEverythingInManually"
                components={{
                  action: (
                    <Text
                      as={'span'}
                      fontWeight={'bold'}
                      textDecoration={'underline'}
                      cursor={'pointer'}
                      textColor={actionTextColor}
                      onClick={() => {
                        onOpenDropzone?.(parentRelatedParty);
                        close();
                      }}
                    ></Text>
                  ),
                }}
              ></Trans>
            </Text>

            <MutateRelatedPartyForm
              entityType={entityType}
              isHideRelationRadioButton={!!relatedPartyId}
              isShowCorpAuthority={
                relationType === RelatedPartyRelationType.PERSON &&
                parentRelatedParty?.relationType === RelatedPartyRelationType.COMPANY
              }
              form={form}
              dispatch={dispatch}
              certidaoAccessCode={certidaoAccessCode}
              onCertidaoCodeChange={setCertidaoAccessCode}
              onTabIndexChange={setTabIndex}
              isShowCompanyImportByCode
            />
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              isLoading={isLoading || isLoadingSubmitByCode}
              onClick={onSubmitClick}
              title={t('add')}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalDocumentsValidationError
        jobId={jobId}
        errorType={isShowErrorModal ? DocumentsValidationErrorTypeEnum.CertidaoRegistoComercialAccessCodeInvalid : null}
        errorModalAdditionalText={errorModalAdditionalText}
        onClose={() => setIsShowErrorModal(false)}
      />
    </>
  );
};
