import { Icon } from '@chakra-ui/react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { AppButton, AppButtonType } from '../appButton';

export interface CheckboxProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: () => void;
}

export function Checkbox({ isChecked, isDisabled, onCheck }: CheckboxProps) {
  return (
    <AppButton
      buttonType={AppButtonType.ICON}
      disabled={isDisabled}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onCheck();
      }}
      children={
        <Icon
          as={isChecked ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
          color={isChecked ? 'green.300' : 'secondaryGray.600'}
          w="24px"
          h="24px"
        />
      }
    />
  );
}
