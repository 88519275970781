import { Box, useColorMode } from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';
import { globalStyles } from 'theme/styles';
import './phoneInput.css';

export interface PhoneNumberInputProps {
  isReadonly?: boolean;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const PhoneNumberInput = ({ value, isReadonly, onChange, onBlur }: PhoneNumberInputProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderWidth="1px"
      borderRadius="6px"
      borderColor={colorMode === 'light' ? globalStyles.colors.gray['200'] : 'rgba(255, 255, 255, 0.16)'}
      _hover={{
        borderColor: colorMode === 'light' ? globalStyles.colors.gray['300'] : 'rgba(255, 255, 255, 0.24)',
      }}
      _focusWithin={{
        borderColor: colorMode === 'light' ? globalStyles.colors.blue['500'] : '#63b3ed',
        boxShadow: colorMode === 'light' ? '0 0 0 1px #3965FF' : '0 0 0 1px #63b3ed',
      }}
      transition={'all 0.2s'}
    >
      <PhoneInput
        disabled={isReadonly}
        country={'pt'}
        inputStyle={{
          width: '100%',
          border: 'none',
          height: '50px',
          backgroundColor: 'transparent',
          borderRadius: '10px',
          color: colorMode === 'light' ? globalStyles.colors.navy[750] : 'white',
        }}
        buttonStyle={{
          border: 'none',
          backgroundColor: 'transparent',
          borderRadius: '6px',
          padding: '1px',
        }}
        dropdownStyle={{
          borderColor: globalStyles.colors.gray['200'],
          backgroundColor: colorMode === 'light' ? 'white' : globalStyles.colors.gray['700'],
          borderRadius: '6px',
          maxHeight: '170px',
        }}
        dropdownClass={colorMode === 'light' ? 'phone-dropdown' : 'phone-dropdown-dark'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Box>
  );
};
