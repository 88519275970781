import { useTranslation } from 'react-i18next';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';

import { Job, JobStatus } from 'api';
import { JobTemplatesWidget } from '../JobTemplatesWidget/JobTemplatesWidget';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { Link, useNavigate } from 'react-router-dom';
import { JobMainInfoWidget } from '../../components/job-main-info';
import { useJobForm } from '../JobDetailsWidget/useJobForm';
import { RelatedPartiesWidget } from './BuyersWidget';
import { useRelatedParties } from './useRelatedParties';
import { RelatedPartyEntityType, useInitJobPartiesMutation } from 'api/related-parties';
import { WaitPleasePopup } from 'components/waitPleasePopup/WaitPleasePopup';
import { JobErrorModal } from '../JobDetailsWidget/modals/JobErrorModal';
import { useCallback, useEffect, useState } from 'react';
import { VCAccordion, VCAccordionType } from '../../../../../libs/ui/atoms/src/lib/accordion';
import { RightOfFirstRefusalModal } from '../../../../../components/rightOfFirstRefusalModal/RightOfFirstRefusalModal';
import { CopyContractsTool } from '../../../../../components/copyContractsTool/CopyContractsTool';
import { useTypedSelector } from '../../../../../store';
import { JobSignButton } from '../../../../../components/jobSignButton/JobSignButton';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../../libs/ui/atoms/src/lib/appButton';

export interface AddContractsWidgetProps {
  job: Job;
  isLoadingSubmit: boolean;
  hideExtraData?: boolean;
  onSubmit: () => Promise<void>;
}

export function AddContractsWidget({ job, isLoadingSubmit, hideExtraData = false, onSubmit }: AddContractsWidgetProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isHelpWidgetOpen = useTypedSelector(
    state => state.helpTools.selectedHelpTicketType && !state.helpTools.isHelpTicketPanelCollapsed,
  );

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');

  const { nameField, descriptionField, setName, setDescription, validateForm } = useJobForm(job.name, job.description);

  const [isJobErrorModalOpen, setIsJobErrorModalOpen] = useState(false);

  const [initJobParties, { isLoading: isLoadingInitJobParties, error: initError }] = useInitJobPartiesMutation();
  const [isInited, setIsInited] = useState(false);
  const init = useCallback(() => {
    if (isLoadingInitJobParties) return;
    initJobParties({ jobId: job.id }).then(() => setIsInited(true));
  }, [initJobParties, isLoadingInitJobParties, job.id]);

  useEffect(() => {
    if (isInited) return;
    init();
  }, [init, isInited]);

  const handleSubmit = async () => {
    if (validateForm()) {
      await onSubmit();
    }
  };

  useDidUpdateEffect(() => {
    if (job.status === JobStatus['Job is completed'] && job.statusMetadata?.status === 'ok') {
      navigate(`/job-result/${job.id}`);
    }
  }, [job.status]);

  const [prevJobStatus, setPrevJobStatus] = useState(job.status);
  useDidUpdateEffect(() => {
    if (prevJobStatus === JobStatus.Submitted && job.status !== JobStatus.Submitted) {
      if (job.statusMetadata?.status !== 'ok') {
        setIsJobErrorModalOpen(true);
      }
    }

    setPrevJobStatus(job.status);
  }, [job, prevJobStatus, setPrevJobStatus, setIsJobErrorModalOpen]);

  const {
    sellers,
    buyers,
    onBuyerSearchChange,
    onSellerSearchChange,
    onDeleteItems,
    onResetToDefault,
    onCreate,
    onUpdate,
    loadBuyers,
    loadSellers,
  } = useRelatedParties(job.id, isInited);

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
      >
        <Flex direction="column" gap="24px">
          <JobMainInfoWidget
            isReadOnly
            job={job}
            hideExtraData={hideExtraData}
            nameField={nameField}
            descriptionField={descriptionField}
            onNameUpdate={setName}
            onDescriptionUpdate={setDescription}
          />

          <Flex
            gap={'24px'}
            flexDirection={{
              base: 'column',
              '2xl': isHelpWidgetOpen ? 'column' : 'row',
              '3xl': 'row',
            }}
          >
            <RelatedPartiesWidget
              jobId={job.id}
              isShowCreateButton
              entityType={RelatedPartyEntityType.BUYER}
              title={t('relatedParties.buyers')}
              items={buyers}
              onSearch={onBuyerSearchChange}
              onDeleteItems={onDeleteItems}
              onResetToDefault={onResetToDefault}
              onUpdate={onUpdate}
              onCreate={onCreate}
              onLoad={loadBuyers}
            />
            <RelatedPartiesWidget
              jobId={job.id}
              entityType={RelatedPartyEntityType.SELLER}
              isShowCreateButton
              title={t('relatedParties.sellers')}
              items={sellers}
              onSearch={onSellerSearchChange}
              onDeleteItems={onDeleteItems}
              onResetToDefault={onResetToDefault}
              onUpdate={onUpdate}
              onCreate={onCreate}
              onLoad={loadSellers}
            />
          </Flex>

          <JobTemplatesWidget jobId={job.id} title={t('contracts')} isHideIncludeContracts isAccordion />

          <Card flexDirection="column" w="100%" p="0" position="static">
            <VCAccordion
              type={VCAccordionType.DEFAULT}
              title={t('tools')}
              bodyChildren={
                <Flex flexDirection={'column'} p="0 24px 24px 24px" gap="8px">
                  <>
                    <JobSignButton isAccordionItem />
                    <RightOfFirstRefusalModal jobId={job.id} />
                    <CopyContractsTool job={job} />
                  </>
                </Flex>
              }
            />
          </Card>

          <Flex direction="column" gap="24px">
            <Flex gap="24px" justifyContent="end" direction={{ base: 'column', md: 'row' }}>
              <Link to={`/job-result/${job.id}`}>
                <AppButton
                  buttonType={AppButtonType.PRIMARY_MAIN}
                  title={t('pages.details.cancel')}
                  colorSchemes={[AppButtonColorScheme.DISABLED]}
                />
              </Link>
              <AppButton
                title={t('pages.details.submitContracts')}
                buttonType={AppButtonType.PRIMARY_MAIN}
                onClick={handleSubmit}
                isLoading={isLoadingSubmit}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>

      <WaitPleasePopup job={job} />

      {job.statusMetadata?.traceId && (
        <JobErrorModal
          isOpen={isJobErrorModalOpen}
          onClose={() => setIsJobErrorModalOpen(false)}
          traceId={job.statusMetadata?.traceId}
        />
      )}
    </>
  );
}
